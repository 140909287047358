import React from "react";

const candidateCard = ({ name, img, desc, rr }) => {
  return (
    <div>
      <div className="p-2 bg-light border rounded-3 img-block mx-2">
        <img
          src={img}
          className="img-fluid rounded"
          height={200}
          width={200}
          alt={name}
          ref={rr}
        />
        <div className="">
          <h4>{name}</h4>
          <p className="text-muted">{desc}</p>
        </div>
      </div>
    </div>
  );
};

export default candidateCard;
