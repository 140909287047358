import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import "animate.css";

import Logo from "../images/interface.jpg";
import NP from "../images/nepal.png";
import UK from "../images/uk.png";
import SUP from "../images/icons/cs.png";
import Sign from "../images/icons/interview.png";
import Guide from "../images/icons/guide.png";
import AUS from "../images/icons/sydney.png";
import CAN from "../images/icons/toronto.png";
import SUK from "../images/icons/subway.png";
import USA from "../images/icons/usa.png";
import Pick from "../images/icons/pickup.png";
import Bank from "../images/icons/bank.png";
import ACC from "../images/icons/resident.png";
import IELTS from "../images/icons/test.png";
import TOEFL from "../images/icons/testing.png";
import PTE from "../images/icons/eng.png";
import DOC from "../images/icons/documents.png";
import ASS from "../images/icons/to.png";
import Coun from "../images/icons/conversation.png";
import useInterfaceContext from "./hooks/useInterfaceContext";

const Header = () => {
  const navigate = useNavigate();
  const { login } = useInterfaceContext();

  return (
    <div className="d-flex flex-column">
      <div className="container bg-white">
        <div className="d-flex flex-row-reverse">
          <div className="p-2">
            <div role="button" className="text-warning" onClick={() => ""}>
              <svg height="20" width="40" viewBox="0 0 640 512">
                <path
                  fill="currentColor"
                  d="M0 128C0 92.7 28.7 64 64 64H256h48 16H576c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H320 304 256 64c-35.3 0-64-28.7-64-64V128zm320 0V384H576V128H320zM178.3 175.9c-3.2-7.2-10.4-11.9-18.3-11.9s-15.1 4.7-18.3 11.9l-64 144c-4.5 10.1 .1 21.9 10.2 26.4s21.9-.1 26.4-10.2l8.9-20.1h73.6l8.9 20.1c4.5 10.1 16.3 14.6 26.4 10.2s14.6-16.3 10.2-26.4l-64-144zM160 233.2L179 276H141l19-42.8zM448 164c11 0 20 9 20 20v4h44 16c11 0 20 9 20 20s-9 20-20 20h-2l-1.6 4.5c-8.9 24.4-22.4 46.6-39.6 65.4c.9 .6 1.8 1.1 2.7 1.6l18.9 11.3c9.5 5.7 12.5 18 6.9 27.4s-18 12.5-27.4 6.9l-18.9-11.3c-4.5-2.7-8.8-5.5-13.1-8.5c-10.6 7.5-21.9 14-34 19.4l-3.6 1.6c-10.1 4.5-21.9-.1-26.4-10.2s.1-21.9 10.2-26.4l3.6-1.6c6.4-2.9 12.6-6.1 18.5-9.8l-12.2-12.2c-7.8-7.8-7.8-20.5 0-28.3s20.5-7.8 28.3 0l14.6 14.6 .5 .5c12.4-13.1 22.5-28.3 29.8-45H448 376c-11 0-20-9-20-20s9-20 20-20h52v-4c0-11 9-20 20-20z"
                />
              </svg>
            </div>
            {/* <select className="btn btn-light border" data-width="fit">
            
              <option className="btn" data-content="English">
                English
              </option>
              <option className="btn" data-content="Nepali">
                Nepali
              </option>
            </select> */}
          </div>
          <div className="p-1"></div>
          {login ? (
            <div className="py-2">
              <div
                role="button"
                className="text-warning"
                onClick={() => navigate("login")}
              >
                <svg className="" height="20" width="20" viewBox="0 0 448 512">
                  <path
                    fill="currentColor"
                    d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
                  />
                </svg>
              </div>
            </div>
          ) : null}
        </div>

        <div className="row">
          <div className="col text-center">
            <nav className="navbar">
              <div className="container-fluid ">
                <NavLink className="navbar-brand" to="/" end>
                  <img src={Logo} alt="" height="70" />
                </NavLink>
              </div>
            </nav>
          </div>
          <div className="col-md-4 col-sm-6 p-4">
            <div className="row d-flex flex-fill justify-content-end align-items-end ">
              <div className="form">
                {" "}
                <input
                  type="text"
                  className="form-control form-input bg-light"
                  placeholder="🔎 Search anything..."
                />{" "}
                <span className="left-pan">
                  <svg
                    width="24"
                    height="24"
                    fill="currentColor"
                    className="bi bi-mic"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
                    <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
                  </svg>
                </span>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center py-2">
          <small className="text-uppercase fw-bold text-danger animate__animated animate__headShake animate__delay-2s animate__infinite">
            "The Miles US Pathway - Recruitment / Click to Register! Limited
            Seats only."
          </small>
          <Link
            to="milesUSpathway"
            className="px-4 fw-bold link-info link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
          >
            Learn More.
          </Link>
        </div>
      </div>
      <div className="px-2">
        <div className="border border-1 border-warning rounded shadow bg-warning bg-opacity-80 ">
          <nav className="navbar navbar-light text-dark">
            <div className="container px-2">
              <ul className="nav nav-pills fw-bold">
                <li className="nav-item dropdown">
                  <a
                    href="#"
                    className="nav-link text-muted dropdown-toggle"
                    id="dropdown01"
                    data-bs-toggle="dropdown"
                    aria-current="page"
                  >
                    Study Abroad
                  </a>
                  <div
                    className="dropdown-menu align-items-stretch p-2 rounded-3 shadow-lg"
                    id="dropdown01"
                    aria-labelledby="dropdown01"
                  >
                    <div className="navNew">
                      <div className="d-flex flex-row gap-2">
                        <Link
                          to="WhySA"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={Sign} height="50" />
                            <div>
                              <strong className="d-block">Why?</strong>
                              <small>Benefits of Study Abroad</small>
                            </div>
                          </div>
                        </Link>
                        <Link
                          to="Guide"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={Guide} height="50" />
                            <div>
                              <strong className="d-block">Guide</strong>
                              <small>Get help on finding best option</small>
                            </div>
                          </div>
                        </Link>

                        <Link
                          to="Contact"
                          className="btn align-items-center gap-3 p-3 lh-sm w-100"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={SUP} height="50" />
                            <div>
                              <strong className="d-block">Support</strong>
                              <small>Get help from our support crew</small>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    href="#"
                    className="nav-link text-muted dropdown-toggle"
                    id="dropdown02"
                    data-bs-toggle="dropdown"
                    aria-current="page"
                  >
                    Explore
                  </a>

                  <div
                    className="dropdown-menu align-items-stretch p-2 rounded-3 shadow-lg"
                    id="dropdown02"
                    aria-labelledby="dropdown02"
                  >
                    <div className="navNew">
                      <div className="d-flex flex-row gap-2">
                        <Link
                          to="Australia"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={AUS} height="50" />
                            <div>
                              <strong className="d-block">Australia</strong>
                              <small>
                                Everything you need to know while Studying at
                                Australia
                              </small>
                            </div>
                          </div>
                        </Link>
                        <Link
                          to="Canada"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={CAN} height="50" />
                            <div>
                              <strong className="d-block">Canada</strong>
                              <small>Get help on finding best option</small>
                            </div>
                          </div>
                        </Link>

                        <Link
                          to="UK"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={SUK} height="50" />
                            <div>
                              <strong className="d-block">UK</strong>
                              <small>Get help from our support crew</small>
                            </div>
                          </div>
                        </Link>

                        <Link
                          to="milesUS"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={USA} height="50" />
                            <div>
                              <strong className="d-block">MilesUS</strong>
                              <small>
                                The Miles US Pathway: Unlocking Opportunities
                              </small>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    href="#"
                    className="nav-link text-muted dropdown-toggle"
                    id="dropdown03"
                    data-bs-toggle="dropdown"
                    aria-current="page"
                  >
                    Our Services
                  </a>

                  <div
                    className="dropdown-menu align-items-stretch p-2 rounded-3 shadow-lg"
                    id="dropdown03"
                    aria-labelledby="dropdown03"
                  >
                    <div className="navNew">
                      <div className="d-flex flex-row gap-2">
                        <a
                          href="#"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={Coun} height="50" />
                            <div>
                              <strong className="d-block">COUNSELLING</strong>
                              <small>
                                We help you to develop comprehensive plan
                              </small>
                            </div>
                          </div>
                        </a>
                        <a
                          href="#"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={CAN} height="50" />
                            <div>
                              <strong className="d-block">
                                VISA APPLICATION
                              </strong>
                              <small>
                                Get help on navigating through visa process
                              </small>
                            </div>
                          </div>
                        </a>

                        <a
                          href="#"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={DOC} height="50" />
                            <div>
                              <strong className="d-block">GUIDANCE</strong>
                              <small>
                                Get help from our crew on documentation
                              </small>
                            </div>
                          </div>
                        </a>

                        <a
                          href="#"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={ASS} height="50" />
                            <div>
                              <strong className="d-block">ASSISTANCE</strong>
                              <small>
                                Get right set of information on Internship,
                                Scholarship and so on
                              </small>
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                {/* <li className="nav-item">
                  <Link to="milesUSpathway" className="nav-link text-muted">
                    Miles US Pathway
                  </Link>
                </li> */}
                <li className="nav-item dropdown">
                  <a
                    href="#"
                    className="nav-link text-muted dropdown-toggle"
                    id="dropdown04"
                    data-bs-toggle="dropdown"
                    aria-current="page"
                  >
                    Preparation
                  </a>
                  <div
                    className="dropdown-menu align-items-stretch p-2 rounded-3 shadow-lg"
                    id="dropdown04"
                    aria-labelledby="dropdown04"
                  >
                    <div className="navNew">
                      <div className="d-flex flex-row gap-2">
                        <Link
                          to="ielts"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={IELTS} height="50" className="md" />
                            <div>
                              <strong className="d-block">IELTS</strong>
                              <small></small>
                            </div>
                          </div>
                        </Link>
                        <Link
                          to="pte"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={PTE} height="50" />
                            <div>
                              <strong className="d-block">PTE</strong>
                              <small></small>
                            </div>
                          </div>
                        </Link>

                        <Link
                          to="toefl"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={TOEFL} height="50" />
                            <div>
                              <strong className="d-block">TOEFL</strong>
                              <small></small>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
                <li className="nav-item">
                  <Link to="aboutUs" className="nav-link text-muted">
                    About Us
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link text-muted dropdown-toggle"
                    href="#"
                    id="dropdown03"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Essentials
                  </a>
                  <div
                    className="dropdown-menu align-items-stretch p-2 rounded-3 shadow-lg"
                    id="dropdown03"
                    aria-labelledby="dropdown03"
                  >
                    <div className="navNew">
                      <div className="d-flex flex-row gap-2">
                        <Link
                          to="PickUP"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={Pick} height="50" className="md" />
                            <div>
                              <strong className="d-block">Pick Up</strong>
                              <small>Take a through the product</small>
                            </div>
                          </div>
                        </Link>
                        <Link
                          to="Accomodation"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={ACC} height="50" />
                            <div>
                              <strong className="d-block">Accomodation</strong>
                              <small>Get help for finding suitable place</small>
                            </div>
                          </div>
                        </Link>

                        <Link
                          to="Banking"
                          className="btn align-items-center gap-3 p-3 lh-sm"
                        >
                          <div className="" style={{ width: 100 }}>
                            <img src={Bank} height="50" />
                            <div>
                              <strong className="d-block">Banking</strong>
                              <small>Get help from our support crew</small>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>

                <li className="nav-item">
                  <Link to="events" className="nav-link text-muted">
                    Events
                  </Link>
                </li>
              </ul>

              <ul className="nav col-md-2 justify-content-end list-unstyled d-flex">
                <li className="px-2">
                  <a className="text-muted" href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="bi"
                      width="24"
                      height="24"
                      id="twitter"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                    </svg>
                  </a>
                </li>
                <div className="vr"></div>
                <li className="px-2">
                  <a className="text-muted" href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="bi"
                      width="24"
                      height="24"
                      id="instagram"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                    </svg>
                  </a>
                </li>
                <div className="vr"></div>
                <li className="px-2">
                  <a className="text-muted" href="#">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="bi"
                      width="24"
                      height="24"
                      fill="currentColor"
                      id="facebook"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                    </svg>
                  </a>
                </li>
                <div className="vr"></div>
                <li className="px-2">
                  <a
                    href="https://www.youtube.com/channel/UCYaQArAOGzgt7m1YSZbKHtA"
                    className="text-muted"
                    target={"_blank"}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="bi"
                      width="24"
                      height="24"
                      fill="currentColor"
                      id="youtube"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Header;
