import React from "react";
import S1 from "../images/slide1.jpg";
import S2 from "../images/slide2.jpg";
import S3 from "../images/slide3.jpg";
import S4 from '../images/slide4.JPG'

const Slider = () => {
  return (
    <div className="container p-4">
      <div className="card p-2">
        <div
          id="carouselExampleCaptions"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
              <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img src={S3} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5></h5>
                <p>
                  
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={S4} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5></h5>
                <p>
                 
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={S2} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5></h5>
                <p>
                 
                </p>
              </div>
            </div>
            <div className="carousel-item">
              <img src={S1} className="d-block w-100" alt="..." />
              <div className="carousel-caption d-none d-md-block">
                <h5></h5>
                <p>
                  
                </p>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Slider;
