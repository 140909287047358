import React from 'react'
import Gimg from '../images/icons/guide.png'
import ContactBanner from './ContactBanner'
const Guide = () => {
    return (
        <div className="container px-4 py-5" id="hanging-icons">
            <div className="p-4 my-5 text-center">
                <img className="d-block mx-auto mb-4" src={Gimg} alt="" width="100" />
                <h1 className="display-5 fw-bold mb-4">International Student Guide</h1>
                <div className="col-lg-12 mx-auto">
                    <p className="lead mb-4">
                        Being an international student means more than just going to className - it takes a lot of work. The more resources that you have at your fingertips, the easier the transition and great your success.
                        To help you achieve this goal, we have compiled a number of helpful resources. We know that you already have enough fees to worry about with your education;
                        this is why the majority of our resources are offered to you for free! Start learning and enjoy the wealth of information.
                    </p>

                </div>
            </div>
            <div className="row align-items-md-stretch">
                <div className="col-md-6 p-4">
                    <div className="h-100 p-5 bg-light bg-opacity-50 border border-warning rounded-3">
                        <h4>Research</h4>
                        <p className='lead py-4 textJustify'>
                            Do Your own Research! Investigate courses, universities and their locations. Look up on the teaching modules, research opportunities, campus life and employment prospects before you narrow down your selection. You can ask your friends and family or reach out to the alumni of universities you are interested in for a wider perspective. Use Facebook or LinkedIn to reach out to them more effectively. Also, do remember to include your budget and available scholarships as a part of your research.
                        </p>

                    </div>
                </div>
                <div className="col-md-6 p-4">
                    <div className="h-100 p-5 bg-light bg-opacity-50 border border-warning rounded-3">
                        <h4>University/College Search</h4>
                        <p className='lead py-4 textJustify'>
                            The school you attend as an international student can affect your level of education and experience abroad. We can help you find a school with the qualifications, location and budget that you want.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 p-4">
                    <div className="h-100 p-5 bg-light bg-opacity-50 border border-warning rounded-3">
                        <h4>Financial Aid</h4>
                        <p className='lead py-4 textJustify'>
                            For many students, one of the most difficult parts of becoming an international student is finding enough financial aid to make the journey happen. There are multiple outlets available if you are looking for additional funds; you just need to know where to look. Find information on international student loans, scholarships and even work & study.
                        </p>

                    </div>
                </div>
                <div className="col-md-6 p-4">
                    <div className="h-100 p-5 bg-light bg-opacity-50 border border-warning rounded-3">
                        <h4>International Scholarships</h4>
                        <p className='lead py-4 textJustify'>
                            Scholarships are free money that can be used toward your education and should be your first stop when looking for aid to help fund your studies. We can assist you through hundreds of awards, but you can easily find scholarships that you are eligible for by searching country, field of study, or university.
                        </p>

                    </div>
                </div>
                <div className="col-md-6 p-4">
                    <div className="h-100 p-5 bg-light bg-opacity-50 border border-warning rounded-3">
                        <h4>TEST Preparation</h4>
                        <p className='lead py-4 textJustify'>
                            SAT, TOEFL, GRE, GMAT, IELTS, PTE, you may have heard about these tests, but do you really know what they are and if you will need to take them? Get the break down on what each test is and who typically takes each test. If you find a test you will have to take, get tips and sample questions in our test prep center to help you prepare.
                        </p>
                    </div>
                </div>

                <div className="col-md-6 p-4">
                    <div className="h-100 p-5 bg-light bg-opacity-50 border border-warning rounded-3">
                        <h4>VISA & Immigration</h4>
                        <p className='lead py-4 textJustify'>
                            Depending on your purpose, you will be issued a certain type of student visa. To obtain a visa for your time inside the country, you must meet certain requirements as indicated by the respective Government; these vary depending on the type of visa you are applying for. Find out what visa you will need to apply for, how to apply, and the requirements you will need to meet.
                        </p>
                    </div>
                </div>

            </div>

            <div className="p-4 my-5 text-center">
                <h1 className="display-5 fw-bold p-4">At Interface, we provide you proper guidance.</h1>
            </div>
            <div className="row align-items-md-stretch mb-5">
                <div className="col-md-6 p-4">
                    <div className="h-100 p-5 bg-light border border-warning border-2 rounded-3">
                        <span className='text-muted'>Step 1:</span>
                        <h4 className='py-2'>Talk to Us</h4>
                        <p className='lead py-2 textJustify'>
                            When you’re ready, visit us. Our counsellors are professionally certified and are a friendly face and wealth of first-hand professional and personal experience from different study locations all over the world.

                            Bring your wish list and course preferences along with you. Our counsellor will go through all the details to ensure the best possible fit between you, your future university and your area of study.
                        </p>

                    </div>
                </div>

                <div className="col-md-6 p-4">
                    <div className="h-100 p-5 bg-light border border-warning border-2 rounded-3">
                        <span className='text-muted'>Step 2:</span>
                        <h4 className='py-2'>TEST Preparation</h4>
                        <p className='lead py-2 textJustify'>
                            If you need to take an English test to qualify for your course, practice hard to get good grades. In a test such as IELTS, TOEFL or PTE, it is important to be grammatically sound besides having a wide range of vocabulary.
                        </p>

                    </div>
                </div>

                <div className="col-md-6 p-4">
                    <div className="h-100 p-5 bg-light border border-warning border-2 rounded-3">
                        <span className='text-muted'>Step 3:</span>
                        <h4 className='py-2'>Application</h4>
                        <p className='lead py-2 textJustify'>
                            After you’ve chosen your course and university or school, it’s time to apply. Our counsellor will support you on your university and course applications. We follow legal and ethical standards.  Our counsellor will be in communication with your chosen university or school to ensure your chances of acceptance.

                        </p>

                    </div>
                </div>
                <div className="col-md-6 p-4">
                    <div className="h-100 p-5 bg-light border border-warning border-2 rounded-3">
                        <span className='text-muted'>Step 4:</span>
                        <h4 className='py-2'>Offer Letter/Acceptance</h4>
                        <p className='lead py-2 textJustify'>
                            Once your application is received, the university or school will assess it thoroughly and notify you of the result. It can take up to a few weeks (or longer for postgraduate applications) for your application to be processed.

                            If your application is successfully selected, you’ll receive a letter of offer and an acceptance form. Before you accept the offer, go through it carefully to check for any conditions that may apply.

                            If you are accepted for more than one course or school, we will help you decide on the best option for you.
                        </p>

                    </div>
                </div>
                <div className="col-md-6 p-4">
                    <div className="h-100 p-5 bg-light border border-warning border-2 rounded-3">
                        <span className='text-muted'>Step 5:</span>
                        <h4 className='py-2'>Student VISA</h4>
                        <p className='lead py-2 textJustify'>
                            After accepting the letter of offer the most important task is to apply for your student visa. Since each country has its own set of regulations to fulfill, we will guide you through the visa application process and help prepare the documents needed for your submission.
                        </p>

                    </div>
                </div>
                <div className="col-md-6 p-4">
                    <div className="h-100 p-5 bg-light border border-warning border-2 rounded-3">
                        <span className='text-muted'>Step 6:</span>
                        <h4 className='py-2'>READY !!</h4>
                        <p className='lead py-2 textJustify'>
                            Finally, there will be important to-do things you need to consider before flying abroad. This will include exchanging money, insurance, accommodation, SIM cards, opening a bank account, country rules and adapting to new culture. We also provide regular pre-departure sessions throughout the year to help prepare you for student life in your new country.
                        </p>

                    </div>
                </div>
            </div>

            <ContactBanner />

        </div>
    )
}

export default Guide