import "./App.css";
import React from "react";
import MessengerCustomerChat from "react-messenger-customer-chat";
import Header from "./components/Header";
import Innerbody from "./components/Innerbody";
import Footer from "./components/Footer";
import Login from "./components/Login";
import About from "./components/aboutUs";
import { Routes, Route, Outlet } from "react-router-dom";
import WhySA from "./components/WhySA";
import Banking from "./components/Banking";
import Guide from "./components/Guide";
import Accomodation from "./components/Accomodation";
import Pickup from "./components/Pickup";
import Contact from "./components/Contact";
import Australia from "./components/Australia";
import IELTS from "./components/IELTS";
import PTE from "./components/PTE";
import TOEFL from "./components/TOEFL";
import Canada from "./components/Canada";
import UK from "./components/uk";
import USA from './components/Usa'
import ExploreServices from "./components/exploreServices";
import AllUniversities from "./components/allUniversities";
import AllReviews from "./components/allReviews";
import ServerData from "./components/ServerData";
import { InterfaceContextProvider } from "./components/context/interfaceContext";
import Events from "./components/Events";
import MilesUSpathway from "./components/pages/milesUSpathway";
import MilesAction from "./components/pages/milesAction";
import EventCard from "./components/pages/eventCard";
import ArticleCard from "./components/pages/articleCard";

function App() {
  return (
    <InterfaceContextProvider>
      <Header />
      <Routes>
        <Route path="/" element={<Innerbody />} />
        <Route path="login" element={<Login />} />
        <Route path="interestedData" element={<ServerData />} />
        <Route path="aboutUS" element={<About />} />
        <Route path="WhySA" element={<WhySA />} />
        <Route path="Banking" element={<Banking />} />
        <Route path="Contact" element={<Contact />} />
        <Route path="Guide" element={<Guide />} />
        <Route path="Accomodation" element={<Accomodation />} />
        <Route path="PickUP" element={<Pickup />} />
        <Route path="Australia" element={<Australia />} />
        <Route path="Canada" element={<Canada />} />
        <Route path="UK" element={<UK />} />
        <Route path="USA" element={<USA />} />
        <Route path="ielts" element={<IELTS />} />
        <Route path="pte" element={<PTE />} />
        <Route path="toefl" element={<TOEFL />} />
        <Route path="exploreServices" element={<ExploreServices />} />
        <Route path="allUniversities" element={<AllUniversities />} />
        <Route path="allReviews" element={<AllReviews />} />
        <Route path="article/:id" element={<ArticleCard/>}/>
        <Route path="events" element={<Events/>}/>
        <Route path="event/:id" element={<EventCard/>}/>
        <Route path="milesUSpathway" element={<MilesAction/>}/>
        <Route path="milesUS" element={<MilesUSpathway/>}/>
        <Route path="*" element={<div className="container min-vh-100"><p className="py-5">Illegal Access. Not Authorized.</p></div>} />
      </Routes>
      <Outlet />
      <Footer />
      <MessengerCustomerChat pageId="<PAGE_ID>" appId="<APP_ID>" />
    </InterfaceContextProvider>
  );
}

export default App;
