import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./index.css";
import App from "./App";
import ScrollToTop from "./components/scrollToTop";
import Dashboard from "./components/admin/dashboard";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Router>
    <ScrollToTop />
    <Routes>
      <Route path="/*" element={<App />} />
      <Route path="/dashboard" element={<Dashboard/>}/>
    </Routes>
  </Router>
);
