import React, { useRef } from "react";
import { Rerousel } from "rerousel";
import MilesCard from "./milesCard";

const Uni = [
  { name: "", img: "/images/miles/1.PNG", desc: "" },
  { name: "", img: "/images/miles/2.PNG", desc: "" },
  { name: "", img: "/images/miles/3.PNG", desc: "" },
  { name: "", img: "/images/miles/4.PNG", desc: "" },
  { name: "", img: "/images/miles/5.PNG", desc: "" },
  { name: "", img: "/images/miles/6.PNG", desc: "" },
  { name: "", img: "/images/miles/7.PNG", desc: "" },
  { name: "", img: "/images/miles/8.PNG", desc: "" },
  { name: "", img: "/images/miles/9.PNG", desc: "" },
  { name: "", img: "/images/miles/10.PNG", desc: "" },
  { name: "", img: "/images/miles/11.PNG", desc: "" },
  { name: "", img: "/images/miles/12.PNG", desc: "" },
];

const MilesSlider = () => {
  const successRef = useRef(null);

  return (
    <div className="flex flex-row">
      <Rerousel itemRef={successRef} className="">
        {Uni.map((suc) => {
          return (
            <div className="p-2">
              <MilesCard
                key={suc.img}
                name={suc.name}
                img={suc.img}
                desc={suc.desc}
                rr={successRef}
              />
            </div>
          );
        })}
      </Rerousel>
    </div>
  );
};

export default MilesSlider;
