import React from 'react'
import Acc from '../images/icons/resident.png'

const Accomodation = () => {
    return (
        <div className="container px-4 py-5" id="hanging-icons">
            <div class="px-4 py-5 my-5 text-center">
                <img class="d-block mx-auto mb-4" src={Acc} alt="" width="100" />
                <h1 class="display-5 fw-bold">ACCOMODATION</h1>
                <div class="col-lg-12 mx-auto">
                    <p class="lead mb-4 textJustify">
                       We all know that when travelling abroad, accommodation can be hard to find if you have never been to that particular country before and you are unsure which real estate or housing rental company you should trust. A lot of international students book themselves into a hotel or hostel before finding something more permanent in the area.

                        Staying in a hotel for this time can be very expensive, especially on a student budget, and staying in a hostel room can become impersonal and cramped. However, a great alternative lodging choice that is more suitable for these sort of situations, is to stay in private accommodation.
                    </p>
                    <p className='textJustify'>
                    When it comes to housing, international students have a few options. You may be wondering which option is the best and that’s really up to you to decide. Each option comes with pros and cons but it’s important to do your own research to determine which housing option is the best fit for you. On this page, we’ll familiarize you with some of the housing options you have as an international student.
                    </p>
                    <ul className='textJustify py-4'>
                        <li>On Campus Housing</li>
                        <li>Off-Campus Housing</li>
                    </ul>

                </div>
            </div>

        </div>
    )
}

export default Accomodation