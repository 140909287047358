import React from 'react'
import Pick from '../images/icons/airport.png'

const Pickup = () => {
  return (
    <div className="container px-4 py-5" id="hanging-icons">
      <div className="px-4 py-5 my-5 text-center">
        <img className="d-block mx-auto mb-4" src={Pick} alt="" width="100" />
        <h1 className="display-5 fw-bold">PICK UP</h1>
        <div className="col-lg-12 mx-auto">
          <p className="lead mb-4 textJustify">
            You must be all set with your luggage and flight plans. Having come this far, there is now just a small matter of finding a ride to take you to your accommodation after you land abroad.

            It could be daunting to step out of the airport and find yourself in a whole new country. You may not know a great deal about the locality or how to find your way around town. Luckily, there are on-arrival services that you can avail, to make your life easier.

            You could have your University arrange a vehicle to pick you up from the airport. Here’s a look at your options.

          </p>
          <ul className='fw-bold textJustify'>
            <li>Free Rides</li>
            <li>Paid Services</li>
            <li>Public Bus</li>
          </ul>

        </div>
      </div>
    </div>
  )
}

export default Pickup