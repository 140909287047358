import React from "react";
import { useNavigate } from "react-router-dom";

const Universities = ({ uniD }) => {
  const navigate = useNavigate()
  return (
    <div className="container p-4">
      <h2 className="pb-2 border-bottom">Universities</h2>
      <div className="row row-cols-1 row-cols-md-6 gap-4 p-1">
        {uniD.map((curE) => {
          const {
            id,
            name,
            img,
            address,
            country,
            discription,
            program,
            courses,
            rank,
            fees,
            intake,
            eURL,
          } = curE;
          return (
            <a
              href={eURL}
              target={"_blank"}
              className="btn btn-outline-dark"
              key={id}
            >
              <div className="col">
                <div className="h-100">
                  <img src={img} className="card-img-top" alt={name} />
                  <div className="card-body">
                    <h6 className="fs-6 fw-bold">{name}</h6>
                    <p className="card-text"></p>
                  </div>
                </div>
              </div>
            </a>
          );
        })}
      </div>

      <button className="btn btn-primary float-end" onClick={()=>{navigate('/allUniversities')}}> More Universities</button>
    </div>
  );
};

export default Universities;
