import React from "react";

const Footer = () => {
  return (
    <div className="container">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top border-2">
        <div className="col-md-4 d-flex align-items-center">
          <span className="text-muted">
            &copy; 2081(BS) Interface Education Center |
            <a
              href="http://www.aen.com.np"
              target={"_blank"}
              className="btn btn-sm"
            >
              ♒
            </a>
          </span>
        </div>

        <ul className="nav col-md-4 justify-content-end list-unstyled d-flex">
          <li className="p-1">
            <button
              className="btn btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop1"
            >
              terms
            </button>
            <div
              className="modal fade"
              id="staticBackdrop1"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog rounded rounded-4 bg-light text-dark">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      Terms & Condition
                    </h5>
                  </div>
                  <strong className="text-center">
                    Welcome to the internet sites, application and services
                    provided by Interface Education Center.
                  </strong>
                  <div className="modal-body p-4">
                    <p className="dropcap textJustify py-2">
                      BY JOINING OR EACH TIME YOU ACCESS AND USE THE SERVICES,
                      YOU SIGNIFY THAT YOU HAVE READ AND UNDERSTAND, AND AGREE
                      TO BE BOUND BY, THESE TERMS. IF YOU DO NOT AGREE TO THESE
                      TERMS, YOU ARE NOT PERMITTED TO USE THE SERVICES. These
                      Terms have the same force and effect as an agreement in
                      writing.
                    </p>
                    <ul className="list-links">
                      <li>
                        <a href="#">Responsibilities</a>
                      </li>
                      <li>
                        <a href="#">User Submissions</a>
                      </li>
                      <li>
                        <a href="#">
                          Copyright Infringement and Trademark Rights
                        </a>
                      </li>
                      <li>
                        <a href="#">Termination</a>
                      </li>
                      <li>
                        <a href="#">Modifications To Terms & Services</a>
                      </li>
                    </ul>

                    <blockquote className="pt-4">
                      <p>
                        If you have any questions about this Terms & Condition,
                        please feel free to contact us by email at{" "}
                        <strong>support@interface.edu.np</strong>
                      </p>
                    </blockquote>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Understood
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li className="p-1">
            <button
              className="btn btn-sm"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              privacy
            </button>
            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog rounded rounded-4 bg-light text-dark">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="staticBackdropLabel">
                      Privacy
                    </h5>
                  </div>
                  <div className="modal-body p-4">
                    <p className="dropcap textJustify">
                      Your privacy is important to us and we have prepared this
                      Privacy Policy to explain to you how we collect, use, and
                      share information we obtain through your use of the
                      Internet sites, applications and services (Services) that
                      are operated by, controlled by or are affiliate with
                      Interface Education Center, including without limitation
                      (collectively, we, us or our). This Privacy Policy does
                      not govern our use of any information you provide to us
                      when you call us, write to us, or communicate with us in
                      any manner other than through the Services. Because the
                      Internet is global, information about you that we collect
                      or that you submit may be transferred to, processed in,
                      and held in countries (including the NEPAL) other than the
                      one in which you reside. By using the Services, you
                      explicitly consent to such use of your information and
                      agree to the terms of this Privacy Policy.
                    </p>
                    <ul className="list-links">
                      <li>
                        <a href="#">Information Collection.</a>
                      </li>
                      <li>
                        <a href="#">Information Submission</a>
                      </li>
                      <li>
                        <a href="#">Information Sharing</a>
                      </li>
                      <li>
                        <a href="#">Public Information</a>
                      </li>
                      <li>
                        <a href="#">Cookies</a>
                      </li>
                      <li>
                        <a href="#">Advertising</a>
                      </li>
                    </ul>

                    <blockquote>
                      <p>
                        If you have any questions about this Privacy Policy,
                        please feel free to contact us by email at{" "}
                        <strong>support@interface.edu.np</strong>
                      </p>
                    </blockquote>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-bs-dismiss="modal"
                    >
                      Understood
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Footer;
