import React, { useState } from 'react'
import Slider from './Slider'
import Feat from './Features'
import InterestedForm from './InterestedForm'
import Reviews from './Reviews'
import ReData from '../data/reviewData'
import Uni from './Universities'
import UniData from '../data/universitiesData'
import QnA from './QnA'
import ContactBanner from './ContactBanner'
import SuccessfulCandidates from './SuccessfulCandidates'
import FrontEvents from './frontEvents'

const Innerbody = () => {

  const [UData, setUData] = useState(UniData)
  const [Rdata, setRdata] = useState(ReData)
  return (
    <>
    <Slider />
    <Feat />
    {/* <FrontEvents/> */}
    <QnA />
    <InterestedForm />
    <Uni uniD = {UData} />
    <Reviews reData ={Rdata} />
    {/* <Events /> */}
    <ContactBanner/>
    <SuccessfulCandidates />
    </>
  )
}

export default Innerbody