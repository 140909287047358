import React from "react";
import AUS from "../images/icons/university.png";
import uni from '../data/universitiesData'
import { useState } from "react";

const AllUniversities = () => {
    const [uniD, setuniD] = useState(uni)
  return (
    <div className="container text-center p-4">
      <div className="p-4 my-5 text-center">
        <img className="d-block mx-auto mb-4" src={AUS} alt="" width="150" />
        <h1 className="display-5 fw-bold">Associated Universities</h1>
      </div>
      <div className="row row-cols-auto row-cols-sm-2 row-cols-md-6 gap-2 p-2">
        {uniD.map((curE) => {
          const {
            id,
            name,
            img,
            address,
            country,
            discription,
            program,
            courses,
            rank,
            fees,
            intake,
            eURL,
          } = curE;
          return (
            <a
              href={eURL}
              target={"_blank"}
              className="btn btn-outline-dark"
              key={id}
            >
              <div className="col">
                <div className="h-100">
                  <img src={img} className="card-img-top" alt={name} />
                  <div className="card-body">
                    <h6 className="fs-6 fw-bold">{name}</h6>
                    <p className="card-text"></p>
                  </div>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default AllUniversities;
