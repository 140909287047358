import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useInterfaceContext from "./hooks/useInterfaceContext";

const Login = () => {
  const navigate = useNavigate();
  const [flagUser, setFlagUser] = useState();
  const [flagPass, setFlagPass] = useState();
  const [flagLogin, setFlagLogin] = useState(false);
  const inputRef = useRef(null);
  const [values, setValues] = useState({ username: "", password: "" });

  //interface context 
  const {user, saveUsername} = useInterfaceContext();


  useEffect(() => {
    inputRef.current.focus();
    setFlagUser(false);
    console.log("user " + flagUser);
  }, [values.username]);

  useEffect(() => {
    setFlagPass(false);
    console.log("pass " + flagPass);
  }, [values.password]);

  useEffect(() => {
    console.log("login " + flagLogin);
    if (!user) {console.log("Username Empty")} else {navigate("/interestedData");}
  }, [flagLogin]);

  const getDate = () => {
    var currentD = new Date();
    console.log(currentD + flagUser + flagPass);
    if (values.username.length < 1) {
      setFlagUser(true);
      console.log(flagUser);
    }

    if (values.password.length < 1) {
      setFlagPass(true);
      console.log(flagPass);
    }

    if (values.username.length>1 && values.password.length>1) {
      if (values.username === "interface" && values.password === "interface") {
        setFlagLogin(false);
        console.log(flagLogin, values.username);
        saveUsername(values.username)
        navigate("/interestedData");
      } else {
        setFlagLogin(true);
      }
    }
  };

  const set = (name) => {
    return ({ target: { value } }) => {
      if(flagLogin) {setFlagLogin(false)}
      setValues((oldValues) => ({ ...oldValues, [name]: value }));
    };
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      getDate()
      console.log('Enter key was pressed');
    }
  }

  return (
    <div className="container justify-content-around p-5 ">
      <div className="col-4 py-4">
        {flagLogin && (
          <span className="fw-bold text-danger">
            Login credentials doesn't match. Please try again.
          </span>
        )}
        <input
          className="form-control form-input bg-light"
          id="username"
          ref={inputRef}
          type="text"
          placeholder="👤 username"
          value={values.username}
          required
          onChange={set("username")}
        />
        {flagUser && (
          <span className="text-danger">Username cannot be empty.</span>
        )}
        <input
          className="form-control form-input bg-light"
          id="password"
          type="password"
          placeholder="🔑 password"
          value={values.password}
          required
          onChange={set("password")}
          onKeyDown={handleKeyDown}
        />
        {flagPass && (
          <span className="text-danger">Password cannot be empty.</span>
        )}
      </div>

      <div className="col-4">
        <button
          className="btn btn-light border rounded nav-pills nav-fill"
          onClick={() => {
            getDate();
          }}
        >
          {" "}
          Login{" "}
        </button>
      </div>
    </div>
  );
};

export default Login;
