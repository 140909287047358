import React from "react";
import { Link } from "react-router-dom";
import f1 from '../images/f1.jpg'
import f2 from '../images/f2.jpg'
import f3 from '../images/f3.jpg'

const features = () => {

  return (
    <div className="container p-4">
      <h2 className="pb-2 border-bottom">Features</h2>
      <div className="row d-flex flex-row row-cols-1 row-cols-lg-3 align-items-stretch g-4 p-4">
        <div className="col ">
          <Link to="aboutUs" className="text-decoration-none">
            <div className="card card-cover h-100 overflow-hidden bg-dark rounded-4 shadow-lg" style={{ backgroundImage: `url(${f1})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  Why us?
                </h2>
              </div>
            </div>
          </Link>
        </div>

        <div className="col">
          <Link to="Australia" className="text-decoration-none">
            <div className="card card-cover h-100 overflow-hidden bg-dark rounded-4 shadow-lg" style={{ backgroundImage: `url(${f2})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  Explore Australia
                </h2>

              </div>
            </div>
          </Link>
        </div>

        <div className="col">
          <Link to="exploreServices" className="text-decoration-none">
            <div className="card card-cover h-100 overflow-hidden bg-dark rounded-4 shadow-lg" style={{ backgroundImage: `url(${f3})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
              <div className="d-flex flex-column h-100 p-5 pb-3 text-white text-shadow-1">
                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  Explore Our Services
                </h2>

              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default features;
