import React from "react";
import uuk from "../images/icons/subway.png";
import imgUK1 from "../images/hd/ek.jpg";
import ContactBanner from "./ContactBanner";

const UK = () => {
  return (
    <div className="container p-4" >
      <div className="p-4 my-5 text-center">
        <img className="d-block mx-auto mb-4" src={uuk} alt="" width="150" />
        <h1 className="display-5 fw-bold">Why UK?</h1>

        <p className="lead mb-4 textJustify">
          Hundreds of thousands of international students study in the UK each
          year, giving the UK a consistently high ranking in popularity. With a
          diverse population and a reputation for prestigious schooling, the UK
          is a natural location for international students to flock to. And for
          students worldwide seeking an English-language education, what better
          place to study than the country that invented the language?
        </p>
        <p className="lead mb-4 textJustify">
          With an excellent higher-education system and hundreds of world-class
          universities, the UK promises a rich experience for international
          students. Our Study UK resource is intended to help you learn more
          about studying in the UK as an international student, and includes
          sections on choosing the UK as a destination, choosing a location
          within the UK, learning more about the country, its history and
          culture, and adapting to life in your adopted country.
        </p>
      </div>
      <div className="border rounded shadow">
        <img src={imgUK1} className="img-fluid" alt="UK" />
      </div>
      <h1 className="display-5 py-4 text-center">
        We will update soon. <br />
        For queries regarding UK, please contact us.
      </h1>
      <ContactBanner />
    </div>
  );
};

export default UK;
