import React, { createContext, useState } from "react";

export const InterfaceContext = createContext();

export const InterfaceContextProvider = ({children}) => {

const [user, setUser] = useState('')
const [login, setLogin] = useState(true)

const saveUsername = (name) => {
    console.log("Username ", name)
    setUser(name)
}
const toogleLogin = () => {
    login ? setLogin(false) : setLogin(true)
}

return (
    <InterfaceContext.Provider value={{user, login, saveUsername, toogleLogin}}>
        {children}
    </InterfaceContext.Provider>
)
    
}