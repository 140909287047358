import React from 'react'
import Abroad from '../images/icons/airplane.png'
import World from '../images/benefits/earth.png'
import EDU from '../images/benefits/education.png'
import DV from '../images/benefits/diversity.png'
import Career from '../images/benefits/career.png'
import People from '../images/benefits/people.png'
import Skills from '../images/benefits/skills.png'
import ContactBanner from './ContactBanner'

const WhySA = () => {
    return (
        <div className="container px-4 py-5" id="hanging-icons">
            <div className="px-4 py-5 my-5 text-center">
                <img className="d-block mx-auto mb-4" src={Abroad} alt="" width="100" />
                <h1 className="display-5 fw-bold">Why Study Abroad?</h1>
                <div className="col-lg-12 mx-auto">
                    <p className="lead mb-4">
                        An international education, whether a semester abroad or a full degree program in another country, can be one of the most rewarding and exhilarating experiences in a person's life.
                        There is so much to be gained from experiencing a new culture, facing new challenges, and opening your mind to a whole new world.
                        International studies allow for the mixing of various cultures, cultivating a greater understanding between nations.
                    </p>

                </div>
            </div>

            <h2 className="pb-2 border-bottom">Benefits of Study Abroad</h2>
            <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                <div className="col d-flex align-items-start p-4">
                    <div className="icon-square text-dark flex-shrink-0 me-3">
                        <img className="" src={World} alt="See the world" width="50" />
                    </div>
                    <div className=''>
                        <h4>SEE THE WORLD</h4>
                        <p className='textJustify'>
                            The biggest reason you should consider a study abroad program is the opportunity to see the world . By studying abroad, you will experience a brand-new country with incredible new outlooks, customs and activities. The benefits of studying abroad include the opportunity to see new terrains, natural wonders, museums and landmarks of your host nation.
                        </p>

                    </div>
                </div>
                <div className="col d-flex align-items-start p-4">
                    <div className="icon-square text-dark flex-shrink-0 me-3">
                        <img className="d-block" src={EDU} alt="Education" width="50" />
                    </div>
                    <div>
                        <h4>EDUCATION</h4>
                        <p className='textJustify'>
                            Another reason you might consider studying abroad is for the chance to experience different styles of education. By enrolling in a study abroad program, you’ll have the chance to see a side of your major that you may not have been exposed to at home.

                            You’ll find that completely immersing yourself in the education system of your host country is a great way to really experience and understand the people, its traditions, and its culture. Education is the centerpiece of any study abroad trip—it is, after all, a study abroad program—and choosing the right school is a very important factor.
                        </p>

                    </div>
                </div>
                <div className="col d-flex align-items-start p-4">
                    <div className="icon-square text-dark flex-shrink-0 me-3">
                        <img className="d-block" src={DV} alt="NEW Culture" width="50" />
                    </div>
                    <div>
                        <h4>NEW CULTURE</h4>
                        <p className='textJustify'>
                            Many students who choose to study abroad are leaving their home for the first time. When they arrive in their new host country, they are fascinated by the distinct cultural perspectives. When you study abroad you will find incredible new foods, customs, traditions, and social atmospheres.

                            You will find that you have a better understanding and appreciation for the nation’s people and history. You will have the opportunity to witness a completely new way of life.
                        </p>

                    </div>
                </div>

                <div className="col d-flex align-items-start p-4">
                    <div className="icon-square text-dark flex-shrink-0 me-3">
                        <img className="d-block" src={Career} alt="Career Choice" width="50" />
                    </div>
                    <div>
                        <h4>CAREER OPPORTUNITIES</h4>
                        <p className='textJustify'>
                            When you finish your study abroad program and return home, you will return with a new perspective on culture, language skills, a great education, and a willingness to learn. Needless to say, all of these are very attractive to future employers.

                            Many students find that they love their host country so much that they decide to seek work there. If you can relate, you will find that a local education will be very valuable when searching for a potential job in that country.
                        </p>

                    </div>
                </div>


                <div className="col d-flex align-items-start p-4">
                    <div className="icon-square text-dark flex-shrink-0 me-3">
                        <img className="d-block" src={People} alt="Personal Development" width="50" />
                    </div>
                    <div>
                        <h4>PERSONAL DEVELOPMENT</h4>
                        <p className='textJustify'>
                            There is nothing quite like being on your own in a foreign country. You might find that studying abroad really brings out your independent nature. Students who study abroad become explorers of their new nation and really discover the curiosity and excitement that they harbor.

                            A benefit to studying abroad is the opportunity to discover yourself while gaining an understanding of a different culture. Being in a new place by yourself can be overwhelming at times, and it tests your ability to adapt to diverse situations while being able to problem solve.
                        </p>

                    </div>
                </div>

                <div className="col d-flex align-items-start p-4">
                    <div className="icon-square text-dark flex-shrink-0 me-3">
                        <img className="d-block" src={Skills} alt="Personal Development" width="50" />
                    </div>
                    <div>
                        <h4>SKILLS & INTERESTS</h4>
                        <p className='textJustify'>
                        Chances are if you’re planning on studying abroad, one of the major draws is the opportunity to study a foreign language. Studying abroad grants you the opportunity to completely immerse yourself in a new language, and there is no better way to learn than to dive right in.
                        <br/>
                        If you are still questioning why to study abroad, you should know that studying in a different country offers many new activities and interests that you may never have discovered if you’d stayed at home. You might find that you have an as-yet undiscovered talent for hiking, water sports, snow skiing, golf, or various other new sports you may never have tried back home.
                        </p>

                    </div>
                </div>

            </div>

            <ContactBanner />
        </div>
    )
}

export default WhySA