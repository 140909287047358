import React from "react";
import Can from "../images/icons/toronto.png";
import imgC from '../images/hd/canada.jpg'
import ContactBanner from "./ContactBanner";

const Canada = () => {
  return (
    <div className="container p-4">
      <div className="p-4 my-5 text-center">
        <img className="d-block mx-auto mb-4" src={Can} alt="" width="150" />
        <h1 className="display-5 fw-bold">Why CANADA?</h1>
        <div className="col-lg-12 mx-auto">
          <p className="lead mb-4 textJustify">
            Canada has become a booming hub for international students whose
            dreams include remaining in the country in which they complete their
            studies. No longer an overlooked global destination to earn a
            degree, Canada also offers a direct path to permanent citizenship -
            an option many international students seek. Canada has ranked as one
            of the top ten places to live in the world for over twenty years,
            and boasts an education system ranked among the best.
          </p>
          <p className="lead mb-4 textJustify">
            Canada is an increasingly popular option for students who seek and
            require a less expensive alternative to studying internationally
            than they might find in the US. Canadian universities are now
            providing equally prestigious degrees as those in the US, often at a
            more affordable cost. Canadians place a great amount of importance
            on learning, and standards in education in Canada are uniformly
            high. There are almost 100 universities in Canada, five of which—the
            University of Toronto, McGill University, University of British
            Columbia, Université de Montréal, and University of Alberta—are
            ranked among the top 100 in the world.
          </p>
        </div>
      </div>
      <div className="">
        <img src={imgC} className="img-fluid border rounded shadow" alt="UK" />
      </div>
      <h1 className="display-5 py-4 text-center">We will update soon. <br/>For queries regarding CANADA, please contact us.</h1>
      <ContactBanner />
    </div>
  );
};

export default Canada;
