import React from "react";
import TOF from "../images/icons/testing.png";
import ContactBanner from "./ContactBanner";

const TOEFL = () => {
  return (
    <div className="container p-4">
      <div class="p-4 my-5 text-center">
        <img class="d-block mx-auto mb-4" src={TOF} alt="" width="100" />
        <h1 class="display-5 fw-bold">TOEFL</h1>
        <p className="lead mb-4 textJustify">
          Many international students consider studying in a college or
          university within the United States. In order to be accepted as an
          international student, most colleges and universities may require them
          to take standardized tests. One such exam, the TOEFL, is used by
          universities to assess the level of English language proficiency. The
          TOEFL exam is necessary in academic setting to be sure students can
          get by in an English-speaking environment.
        </p>
        <p className="fw-normal textJustify">
          The TOEFL is an entrance exam used by several top schools to ascertain
          international students’ aptitude within the English language. The test
          is used to determine the language abilities of non-native English
          speaker by testing abilities in speaking, listening, reading and
          writing skills in English. The exam is administered by the Educational
          Testing Service (ETS), and is offered at several different locations
          around the country. The test was originally a paper-based exam, but
          was later changed into a computer initiated test. While paper-based
          tests are still available, the preferred method is computer-based. The
          test is four hours in length and can be taken a maximum of once a
          week. TOEFL scores stay valid for only two years. Since its inception
          in 1964, the TOEFL test has been taken by over 20 million students.
        </p>
      </div>
      <div className="p-4">
        <h4 className="fs-4 text-decoration-underline">
          What can I expect with the test?
        </h4>
        <p className="lead mb-4 textJustify">
          The TOEFL exam is required by almost every school for foreign students
          who want to study any subject in the US. While there are many
          requirements for international students to study in the US, the TOEFL
          is one of the most important. Schools look to the TOEFL as one of
          their earliest indicators to see if a foreign student is ready to
          study in the US. Students should put in plenty of preparation to
          ensure they get a top score.
        </p>
        <p className="lead mb-4 textJustify">
          The main format for testing is the IBT or Internet-Based testing,
          which was introduced in 2005 to replace the CBT (Computer-Based
          testing). The CBT was officially retired in September of 2006 and so
          the main two formats that are now used are IBT and PBT (Paper-Based
          Testing). The PBT however is rarely used and the most common format is
          the IBT. The IBT format has been rolled out over a series of phases,
          with the USA, Canada, France, Germany and Italy as the first to get
          the new format and then the rest of the world was moved over in 2006.
          Demand for the TOEFL IBT test is often very high and it is advisable
          to book a few months in advance to make sure you get the date you
          want. The IBT consists of a 4 hour test in 4 sections which covers:
          <ul className="row py-4 g-3">
            <li>
              Reading - students are given a reading passage and then posed
              questions about the content they have just read
            </li>
            <li>
              Listening - students are given a passage to listen only once and
              are asked questions about the conversation
            </li>
            <li>
              Speaking - students read passages and answer questions on a
              variety of subjects. These replies are digitally recorded and sent
              to exam judges to be tested.
            </li>
            <li>
              Writing - students are given a passage and then asked to write a
              passage in response to the text
            </li>
          </ul>
          The TOEFL can take up to 4 hours to complete, and students are given
          optional 10 minute breaks.
        </p>
        <p className="lead mb-4 fw-bold textJustify">
          If you need to take the TOEFL for study or visa purposes, you may
          be wondering what to expect.{" "}
          <span className="fw-bold text-warning">
            Interface Education Center
          </span>{" "}
          will answer all of your questions that you may have and
          help you prepare for the test.
        </p>
      </div>
      <ContactBanner />
    </div>
  );
};

export default TOEFL;
