const ReviewData = () => [
    {
        id:1,
        name:'Sachin Dahal',
        img:'/images/reviews/1.jpg',
        country:'NEPAL',
        reviews:'Best Services for abroad studies'
    },
    {
        id:2,
        name:'Sadikshya Dahal',
        img:'/images/reviews/2.jpg',
        country:'NEPAL',
        reviews:'Great experience so far and hoping to gain even more better future opportunities in coming days. Super freindly and supportive team.'
    },
    {
        id:3,
        name:'Aarati Niroula',
        img:'/images/reviews/2.jpg',
        country:'NEPAL',
        reviews:'Great experience so far and hoping to gain even more better future opportunities in coming days. Super freindly and supportive team.'
    },

]
export default ReviewData