import React from "react";

const MilesCard = ({ name, img, desc, rr }) => {
  return (
    <div className="p-1 bg-light border rounded-3 img-block">
      <img
        src={img}
        className="img-cover rounded"
        height={300}
        width={200}
        alt={name}
        ref={rr}
      />
      <div className="">
        <h4>{name}</h4>
        <p className="text-muted">{desc}</p>
      </div>
    </div>
  );
};

export default MilesCard;
