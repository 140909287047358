import { useContext } from "react";
import { InterfaceContext } from "../context/interfaceContext";

const useInterfaceContext = () => {
  const context = useContext(InterfaceContext);
  if (!context) throw Error("Error in Context");
  return context;
};

export default useInterfaceContext;
