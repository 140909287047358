import React from "react";
import Con from "../images/services/counsel.jpg";
import AUS from "../images/icons/24-hours.png";
import UNI from '../images/services/uni.jpg'
import VISA from '../images/services/visa.jpg'
import TEST from '../images/services/test.jpg'
import AID from '../images/services/scholar.jpg'
import ASK from '../images/services/ask.jpg'
import GUI from '../images/services/Guidance.jpg'
import FAIR from '../images/services/fairs.jpg'
import ContactBanner from "./ContactBanner";

const ExploreServices = () => {
  return (
    <div className="container p-4 py-5">
      <div className="p-4 my-5 text-center">
        <img className="d-block mx-auto mb-4" src={AUS} alt="" width="150" />
        <h1 className="display-5 fw-bold">Our Services</h1>
      </div>
      <div className="row row-cols-4 gx-5 text-center">
        <div className="p-4">
          <div className="col h-100 p-2 eServices">
            <button
              className="overflow-hidden rounded rounded-5 border shadow border-warning h-100 w-100"
              style={{
                backgroundImage: `url(${Con})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="text-warning text-shadow-2">
                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  Counselling
                </h2>
              </div>
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="col p-2 eServices">
            <button
              className="overflow-hidden rounded rounded-4 border shadow border-warning h-100 w-100"
              style={{
                backgroundImage: `url(${UNI})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="text-warning text-shadow-2">
                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  University Search
                </h2>
              </div>
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="col p-2 eServices">
            <button
              className="overflow-hidden rounded rounded-4 border shadow border-warning h-100 w-100"
              style={{
                backgroundImage: `url(${VISA})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="text-warning text-shadow-2">
                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  VISA & Immigration
                </h2>
              </div>
            </button>
          </div>
        </div>

        <div className="p-4">
          <div className="col p-2 eServices">
            <button
              className="card h-100 overflow-hidden rounded-4 border shadow border-warning h-100 w-100"
              style={{
                backgroundImage: `url(${TEST})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="text-warning text-shadow-2">
                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  TEST Preparation
                </h2>
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="row row-cols-4 gx-5 py-5 text-center">
        <div className="p-4">
          <div className="col p-2 eServices">
            <button
              className="overflow-hidden rounded rounded-5 border shadow border-warning h-100 w-100"
              style={{
                backgroundImage: `url(${AID})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="text-warning ">
                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold text-shadow-2 ">
                  Scholarship / Aid
                </h2>
              </div>
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="col p-2 eServices">
            <button
              className="overflow-hidden rounded rounded-4 border shadow border-warning h-100 w-100"
              style={{
                backgroundImage: `url(${FAIR})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="text-warning ">
                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold text-shadow-4">
                  Fairs & Seminar
                </h2>
              </div>
            </button>
          </div>
        </div>
        <div className="p-4">
          <div className="col p-2 eServices">
            <button
              className="overflow-hidden rounded rounded-4 border shadow border-warning h-100 w-100"
              style={{
                backgroundImage: `url(${ASK})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="text-warning ">
                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold text-shadow-4">
                  Ask an expert.
                </h2>
              </div>
            </button>
          </div>
        </div>

        <div className="p-4">
          <div className="col p-2 eServices h-100">
            <button
              className="card h-100 overflow-hidden rounded-4 border shadow border-warning h-100 w-100"
              style={{
                backgroundImage: `url(${GUI})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <div className="text-warning text-shadow-4">
                <h2 className="pt-5 mt-5 mb-4 display-6 lh-1 fw-bold text-center">
                  Guidance
                </h2>
              </div>
            </button>
          </div>
        </div>
      </div>

      <ContactBanner />

    </div>
  );
};

export default ExploreServices;
