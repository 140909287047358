import React from "react";
import reviews from "../data/reviewData";
import { useState } from "react";
import AUS from "../images/icons/satisfaction.png";

const AllReviews = () => {
  const [revD, setRevD] = useState(reviews);
  return (
    <div className="container text-center p-4">
      <div className="p-4 my-5 text-center">
        <img className="d-block mx-auto mb-4" src={AUS} alt="" width="150" />
        <h1 className="display-5 fw-bold">Comments & Reviews</h1>
      </div>
      <div className="row g-4">
        {revD.map((curE) => {
          const { id, name, img, country, reviews } = curE;
          return (
            <div className="col-sm-6 col-lg-4 mb-4 h-full" key={id}>
              <div className="card bg-light" style={{height:'250px'}}>
                <div className="card-body h-100">
                  <div className="row h-100">
                    <div className="col-4 d-flex flex-column border border-2 border-warning rounded bg-white overflow-auto mx-auto" >
                      <div className="p-1">
                        <img className="rounded" src={img} alt={name} height={100} />
                      </div>
                      <div className="p-1">
                        <h5 className="card-title fs-6">{name}</h5>
                        <small className="fw-bold">{country}</small>
                        <p className="card-text">
                          <small className="text-muted">
                            2079/04/10
                          </small>
                        </p>
                      </div>
                    </div>
                    <div className="col-8 p-4 overflow-auto">
                      <p className="card-text textJustify ">
                        {reviews}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AllReviews;
