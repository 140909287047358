import React, { useEffect, useState } from "react";
import { Slide, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";

const InterestedData = () => {
  const [DataList, setDataList] = useState([]);
  const [entry, setEntry] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadData = async () => {
    const response = await Axios.get(
      "http://www.interface.edu.np/api/interested"
    )
     .then(async (response) => {
        const dd = await response.data;
        setIsLoading(false)
        if (dd.length < 1) {
          setEntry("No any entries right now.");
        } else {
          setDataList(dd);
        }
      }
      )
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const deleteData = (id) => {
    console.log(id);
    Axios.delete(`http://www.interface.edu.np/api/delete/${id}`)
      .then((res) => {
        console.log("Delete Response : " + res);
        toast.warn("Deleted Successfully !!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          newestOnTop: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme:'colored',
          transition: Slide,
          progress: undefined,
        });
      })
      .catch((err) => {
        console.error(err);
      });

    setTimeout(() => loadData(), 3000);
  };

  return isLoading ? (
    <div className="d-flex justify-content-center py-5">
      <div
        className="spinner-border text-warning"
        role="status"
        style= {{width: "5rem", height: "5rem"}}
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  ) : DataList.length ? (
    <>
      <div className="container p-5">
        <div className="list-group">
          {DataList.map((value, key) => {
            return (
              <div
                className="list-group-item list-group-item-action d-flex gap-3 py-3"
                aria-current="true"
                key={key}
              >
                <div className="d-flex gap-2 w-100 justify-content-between">
                  <button
                    className="btn btn-outline-danger"
                    onClick={() => {
                      deleteData(value.id);
                    }}
                  >
                    Delete
                  </button>
                  <div className="">
                    <h6 className="mb-0">
                      {value.name +
                        ",  interested in " +
                        value.country +
                        " for " +
                        value.course}
                    </h6>
                    <p className="mb-0 opacity-75">
                      {value.email + ", " + value.mobile + ", " + value.address}
                    </p>
                  </div>
                  <small className="opacity-50 text-nowrap">
                    {new Intl.DateTimeFormat("en-GB", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      timeZone: "Asia/Kathmandu",
                    }).format(new Date(value.date))}
                  </small>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ToastContainer />
    </>
  ) : (
    <div className="container p-5">
      <p className="mb-0 opacity-75 py-4">{entry}</p>
    </div>
  );
};

export default InterestedData;
