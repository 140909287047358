import React, { useRef } from "react";
import { Rerousel } from "rerousel";
import CanCard from "./candidateCard";

const successfulCand = [
  { name: "", img: "/images/success/1.jpg", desc: "" },
  {
    name: "",
    img: "/images/success/2.jpg",
    desc: "",
  },
  {
    name: "",
    img: "/images/success/3.jpg",
    desc: "",
  },
  {
    name: "",
    img: "/images/success/4.jpg",
    desc: "",
  },
  {
    name: "",
    img: "/images/success/5.jpg",
    desc: "",
  },
  {
    name: "",
    img: "/images/success/6.jpg",
    desc: "",
  },
  {
    name: "",
    img: "/images/success/7.jpg",
    desc: "",
  },
  {
    name: "",
    img: "/images/success/8.jpg",
    desc: "",
  },
  {
    name: "",
    img: "/images/success/9.jpg",
    desc: "",
  },
  {
    name: "",
    img: "/images/success/10.jpg",
    desc: "",
  },
  {
    name: "",
    img: "/images/success/11.jpg",
    desc: "",
  },
];

const SuccessfulCandidates = () => {
  const successRef = useRef(null);

  return (
    <div className="container">
      <section className="team text-center py-5">
        <div className="container">
          <div className="header my-5">
            <h1>Meet Our Students</h1>
            <p className="text-muted">
              Our successful applicants for abroad study
            </p>
          </div>
          <div className="flex flex-row">
              <Rerousel itemRef={successRef} className="">
                {successfulCand.map((suc) => {
                  return (
                   
                    <CanCard
                      key={suc.img}
                      name={suc.name}
                      img={suc.img}
                      desc={suc.desc}
                      rr={successRef}
                    />
                  );
                })}
              </Rerousel>
            </div>
        </div>
      </section>
    </div>
  );
};

export default SuccessfulCandidates;
