import React from "react";
import Iel from "../images/icons/test.png";
import ContactBanner from "./ContactBanner";

const IELTS = () => {
  return (
    <div className="container p-4">
      <div className="p-4 my-4 text-center">
        <img className="d-block mx-auto mb-4" src={Iel} alt="" width="100" />
        <h1 className="display-5 fw-bold">IELTS</h1>

        <p className="lead mb-4 textJustify">
          International students who are non-native English speakers are
          typically required to provide evidence of their English abilities for
          abroad studies or before they are admitted into college or university.
          IELTS provides a fair, accurate and reliable measure of language
          skills, and is trusted by organizations worldwide as a proof of
          English proficiency. Since 1989, the International English Language
          Testing System (IELTS) has been a global standardized English test
          designed to measure the English language proficiency of people who
          plan to study or work where English is the language of communication.
        </p>
        <p className="fw-normal textJustify">
          IELTS is jointly owned by a global partnership of education and
          language experts – the British Council, IDP: IELTS Australia and
          Cambridge English Language Assessment. Cambridge is responsible for
          IELTS test development due to their world-renowned language and
          research expertise. British Council and IDP are responsible for IELTS
          test delivery throughout the world due to their combined security and
          examination expertise.
        </p>
      </div>
      <div className="p-4">
        <h4 className="fs-4 text-decoration-underline">
          What can I expect with the test?
        </h4>
        <p className="lead mb-4 textJustify">
          IELTS is a paper-and-pencil test with 4 sections: Listening, Reading,
          Writing and Speaking, and includes a live, face-to-face speaking
          interview with a trained and certified examiner. This allows for a
          more realistic measure of a speaker’s English proficiency and
          real-world communication abilities. As a test of English as an
          “international” language, IELTS uses all standard varieties of English
          — American, Australian, and British, and includes different question
          task types (for example, matching plan/map/diagram labelling, multiple
          choice, short-answer, etc.). This is to ensure the test is relevant
          and fair to test takers regardless of cultural background or where
          they studied English.
        </p>
        <p className="lead mb-4 textJustify">
          If you need to take the IELTS test for study or visa purposes, you may
          be wondering what to expect.{" "}
          <span className="fw-bold text-warning">
            Interface Education Center
          </span>{" "}
          will answer all of your questions that you may have and
          help you prepare for test. The International English Language Testing System,
          otherwise known as IELTS, is a standardized English proficiency test
          that can be used for entry into English-speaking universities or as
          part of visa requirements. It is accepted in many English-speaking
          countries such as USA, Australia, Canada, and New Zealand. Each skill
          (reading, writing, listening and speaking) is judged out of 9.
        </p>
      </div>
      <ContactBanner />
    </div>
  );
};

export default IELTS;
