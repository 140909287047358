import React from "react";
import M1 from "../../images/miles/1.PNG";
import M2 from "../../images/miles/8.PNG";
import M3 from "../../images/miles/9.PNG";
import M4 from "../../images/miles/4.PNG";
import M5 from "../../images/miles/workflow.PNG";
import M6 from "../../images/miles/14.png";
import M7 from "../../images/miles/2.PNG";
import M8 from "../../images/miles/5.PNG";
import M9 from "../../images/miles/12.PNG";
import M10 from "../../images/miles/13.PNG";
import M11 from "../../images/miles/black.png";
import M12 from "../../images/miles/limited.png";
import M13 from "../../images/miles/partners.PNG";
import M14 from "../../images/miles/6.PNG";

import V1 from "../../videos/interface1.mp4";
import V2 from "../../videos/interface2.mp4";
import MilesSlider from "./milesSlider";
import MilesAction from "./milesAction";

const MilesUSpathway = () => {
  return (
    <>
      <div className="container p-4">
        <div className="col-md-12 p-lg-5 mx-auto">
          <h3 className="display-5 fw-normal border-bottom ">
            The Miles US Pathway: Unlocking Opportunities for International
            Students
          </h3>
          <div className="my-5 mx-auto bg-primary">
            <img
              width="100%"
              src={M1}
              className="img-cover w-full"
              alt="Australia"
            />
          </div>
          <hr className="featurette-divider" />
          <div className="row featurette my-5">
            <div className="col-md-7">
              <h2 className="featurette-heading fw-normal lh-1">
                Take the first step visit the Interface X Miles Education Nepal.
              </h2>
              <div className="d-flex flex-column flex-md-row p-4 gap-4 py-md-5 align-items-center justify-content-center">
                <div className="list-group list-group-checkable d-grid gap-2 border-0">
                  <ul className="list-group list-group-flush rounded-3 py-3">
                    <h3>What is it all about?</h3>
                    <li className="d-block list-group-item small fw-medium fs-6">
                      A typical Masters in Accounting allows only a 1-year work
                      permit.
                    </li>
                    <li className="d-block list-group-item small fw-medium fs-6">
                      Miles collaborated with US universities to integrate
                      Business Analytics into Masters in Accounting programs,
                      making these programs STEM-certified (Science, Technology,
                      Engineering, and Mathematics).
                    </li>
                    <li className="d-block list-group-item small fw-medium fs-6">
                      Thus, Miles alumni who pursue these programs get the same
                      3-year work permit (like engineers do!).
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <MilesAction/>
            </div>
          </div>
          <hr className="featurette-divider" />

          <div className="my-5">
            <div className="row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border">
              <div className="col-lg-6 p-3 p-lg-5 pt-lg-3">
                <h1 className="display-4 fw-bold lh-1 text-body-emphasis">
                  Why the miles US pathway?
                </h1>
              </div>
              <div className="col-lg-5 offset-lg-1 p-0 overflow-hidden">
                <img className="rounded-lg-3" src={M2} alt="" width="580" />
              </div>
            </div>

            <div className="row featurette align-items-center my-5">
              <h2 className="featurette-heading fw-normal lh-1">
                There's a huge shortage of accountants in the US.
              </h2>
              <div className="col-md-6">
                <img
                  width="100%"
                  src={M3}
                  className="img-cover w-full"
                  alt="US"
                />
              </div>
              <div className="embed-responsive embed-responsive-16by9 col-md-6">
                <video className="embed-responsive-item" width="100%" controls>
                  <source src={V1} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>

            <div className="row featurette align-items-center my-5">
              <h2 className="featurette-heading fw-normal lh-1">
                Building the US pathway for Nepali Accountants
              </h2>
              <div className="col-md-6">
                <img
                  width="100%"
                  src={M4}
                  className="img-cover w-full"
                  alt="US"
                />
              </div>
              <div className="col-md-6 justify-content-center">
                <div className="embed-responsive embed-responsive-16by9 col-md-6">
                  <video
                    className="embed-responsive-item"
                    height="370px"
                    controls
                  >
                    <source src={V2} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>

            <hr className="featurette-divider" />

            <div className="row featurette align-items-center p-4 pb-0 pe-lg-0 pt-lg-5">
              <div className="col-lg-6 p-3">
                <h1 className="display-4 fw-bold lh-1 text-body-emphasis">
                  How it works?
                </h1>
              </div>
              <div className="row py-5">
                <img
                  width="100%"
                  src={M5}
                  className="img-cover w-full"
                  alt="US"
                />
              </div>
              <div className="row">
                <img
                  width="100%"
                  src={M6}
                  className="img-cover w-full"
                  alt="US"
                />
              </div>
              <div className="row featurette align-items-center my-5">
                <h2 className="featurette-heading fw-normal lh-1">
                  Nepali engineers pursue their MS Computer Science in the US,
                  which is a STEM (Science, Technology, Engineering, Math)
                  program. This MS makes them eligible for a 3-year work in the
                  US.
                </h2>
                <img
                  width="100%"
                  src={M7}
                  className="img-cover w-full py-2"
                  alt="US"
                />
                <h2 className="featurette-heading fw-normal lh-1">
                  Miles has now used this tech playbook for accountants! We’ve
                  collaborated with leading US universities and converted their
                  accounting programs to STEM by integrating Business Analytics.
                  This ensures that Nepali accountants eligible for the same
                  3-year work in the US on their student F1 visa (followed by an
                  additional 6 years if they get their H1B visa).
                </h2>
              </div>
            </div>

            <hr className="featurette-divider" />

            <div className="row featurette align-items-center my-5 p-4 pb-0 pe-lg-0 pt-lg-5">
              <div className="col-lg-12 p-3">
                <h1 className="display-4 fw-bold lh-1 text-body-emphasis">
                  Placement in the USA
                </h1>
              </div>
              <div className="row">
                <img
                  width="100%"
                  src={M8}
                  className="img-cover w-full"
                  alt="US"
                />
              </div>
              <div className="row py-5">
                <img
                  width="100%"
                  src={M9}
                  className="img-cover w-full"
                  alt="US"
                />
              </div>
              <div className="row">
                <img
                  width="100%"
                  src={M10}
                  className="img-cover w-full"
                  alt="US"
                />
              </div>
              <div className="row featurette align-items-center my-5">
                <h2 className="featurette-heading fw-normal lh-1">
                  To make the Miles US Pathway affordable, Miles partner
                  universities offer 30% - 70% discount / scholarships
                </h2>
                <div className="">
                  <ol className="list-group list-group-numbered">
                    <li className="list-group-item">
                      Complete 30% coursework in Nepal (at IIM Indore / JAGSOM),
                      leading to a flat reduction of university fees by 30%
                    </li>
                    <li className="list-group-item">
                      Additional scholarships of upto 40% to Miles candidates
                      because of scale (ie., full batch of 30-60 candidates from
                      Miles going to each university)
                    </li>
                    <li className="list-group-item">
                      And to top it up Miles candidates are also eligible for
                      GRE/GMAT as well as IELTS/TOEFL waivers leading to huge
                      savings in time as well as money
                    </li>
                  </ol>
                </div>
              </div>
              <div className="row featurette align-items-center my-5">
                <MilesSlider />
              </div>
            </div>

            <hr className="featurette-divider" />

            <div className="row featurette align-items-center my-5 p-4 pb-0 pe-lg-0 pt-lg-5">
              <div className="col-lg-12 p-3">
                <h1 className="display-4 fw-bold lh-1 text-body-emphasis">
                  Be a part of the INTERFACE X Miles family in the US
                </h1>
                <h2 className="featurette-heading fw-normal lh-1">
                  INTERFACE X Miles Education has collaborated with top
                  AACSB-accredited B-Schools in the US enabling credit transfer
                  from the AACSB-accredited B-School.{" "}
                </h2>
              </div>
              <div className="row py-5">
                <img
                  width="100%"
                  src={M11}
                  className="img-cover w-full"
                  alt="US"
                />
              </div>
              <div className="row">
                <div className="col">
                  <img
                    width="100%"
                    src={M13}
                    className="img-cover w-full"
                    alt="US"
                  />
                </div>
                <div className="col">
                  <img
                    width="100%"
                    src={M12}
                    className="img-cover w-full"
                    alt="US"
                  />
                </div>
                <div className="row mx-auto">
                  <ul>
                    <li>
                      Start by pursuing an online PG Certification from IIM
                      Indore/JAGSoM in Nepal.
                    </li>
                    <li>
                      Transfer credits earned during this certification to a US
                      B-School.
                    </li>
                    <li>
                      Complete 30% of your coursework in Nepal and the remaining
                      portion 70% in the USA.
                    </li>
                    <li>
                      This unique approach allows you to explore job
                      opportunities in the USA, especially for Nepali
                      accountants.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr className="featurette-divider" />

            <div className="row featurette align-items-center my-5 p-4 pb-0 pe-lg-0 pt-lg-5">
              <h1 className="display-4 fw-bold lh-1 text-body-emphasis pb-2">
                Best Part of This Program:
              </h1>
              <div className="row g-4 row-cols-1 row-cols-lg-4">
                <div className="feature col">
                  <h3 className="fs-4 text-body-emphasis">No IELTS/PTE </h3>
                  <p></p>
                </div>
                <div className="feature col">
                  <h3 className="fs-4 text-body-emphasis">
                    Student Loan (Covering all expenses)
                  </h3>
                  <p></p>
                </div>
                <div className="feature col">
                  <h3 className="fs-5 text-body-emphasis">
                    With minimal investment, you will end up with savings of
                    more than NPR 80,00,000
                  </h3>
                  <p></p>
                </div>
                <div className="feature col">
                  <h3 className="fs-5 text-body-emphasis">
                    Estimated earnings $65K/year average x 3 years + Additional
                    $5K from part-time on-campus work during Masters' (subject
                    to availability) *Includes CPA exam fee, IIM Indore
                    programme fee, US University Fee and US Living Cost during
                    Masters
                  </h3>
                  <p></p>
                </div>
              </div>
            </div>

            <hr className="featurette-divider" />

            <div className="row featurette align-items-center my-5 p-4 pb-0 pe-lg-0 pt-lg-5">
              <div className="col-lg-12">
                <h1 className="display-4 fw-bold lh-1 text-body-emphasis">
                  Explore Your Future
                </h1>
              </div>
              <div className="d-flex flex-column flex-md-row p-4 gap-4 py-md-5 align-items-center justify-content-center">
                <div className="list-group w-100">
                  <div className="d-flex gap-2 w-100 justify-content-between list-group-item list-group-item-action gap-3 py-3">
                    <div>
                      <h6 className="mb-0">
                        The Miles Pathway opens doors to a world of
                        possibilities.
                      </h6>
                      <p className="mb-0 opacity-75"></p>
                    </div>
                  </div>

                  <div className="d-flex gap-2 w-100 justify-content-between list-group-item list-group-item-action gap-3 py-3">
                    <div>
                      <h6 className="mb-0">
                        Gain a competitive edge by combining Indian and US
                        education.
                      </h6>
                      <p className="mb-0 opacity-75"></p>
                    </div>
                  </div>

                  <div className="d-flex gap-2 w-100 justify-content-between list-group-item list-group-item-action gap-3 py-3">
                    <div>
                      <h6 className="mb-0">
                        Prepare for a successful career in accounting or
                        finance.
                      </h6>
                      <p className="mb-0 opacity-75"></p>
                    </div>
                  </div>

                  <div className="d-flex gap-2 w-100 justify-content-between list-group-item list-group-item-action gap-3 py-3">
                    <div>
                      <h6 className="mb-0">
                        Whether you’re aiming for a CPA (Certified Public
                        Accountant) or CMA (Certified Management Accountant)
                        qualification, the Miles US Pathway can be your bridge
                        to success. Take the first step toward an international
                        career today!
                      </h6>
                      <p className="mb-0 opacity-75"></p>
                    </div>
                  </div>
                </div>
                <div className="row py-5">
                  <img
                    width="100%"
                    src={M14}
                    className="img-cover w-full"
                    alt="US"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MilesUSpathway;
