import React from "react";
import { useParams } from "react-router-dom";
import Loading from "./loading";
import Error from "./error";
import useFetchSingleEvent from "../hooks/useFetchSingleEvent";

const EventCard = () => {
  const { id } = useParams();

  const { loading, error, data } = useFetchSingleEvent(
    `http://localhost:1337/api/events/${id}?populate=*`
  );

 // console.log(id, " ", data, " ");
  // if (!data || !data.data) return <p>No event data available.</p>;

  //const dataAttributes = data.data.attributes;

  // const imageURL = `http://localhost:1337${data.data.attributes.coverImage.data.attributes.url}`;
  // const authorURL = `http://localhost:1337${data.data.attributes.authorImage.data.attributes.url}`;
  //   const { title, tagline, description, date, authorName, authorDescription } = attributes;

  const formatDateTime = (dateString, locale = "en-US") => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleString(locale, options);
  };

  return (
    <>
      <div className="container p-4">
        <div className="col-md-12 p-lg-5 text-center mx-auto">
          {loading && <Loading />}
          {error && <Error />}
          {data && (
            <>
              <h3 className="display-5 fw-normal border-bottom text-center">
                {data.data.attributes.title}
              </h3>
              <h6 className="fw-normal text-center">
                {data.data.attributes.tagline}
              </h6>

              <div className="p-4">
                <div class="row px-4">
                  <div class="col d-flex flex-column justify-content-start">
                    {data.data.attributes.date && (
                      <>
                        <div className="row">
                          <span className="badge text-bg-warning fs-6 text-uppercase">
                            Date & Time
                          </span>
                        </div>
                        <div className="row text-center">
                          {formatDateTime(data.data.attributes.date)}
                        </div>
                      </>
                    )}
                  </div>

                  <div class="col d-flex flex-column justify-content-start">
                    {data.data.attributes.Location && (
                      <>
                        <span className="badge text-bg-warning fs-6 text-uppercase">
                          Location
                        </span>
                        <div className="row">
                          {data.data.attributes.Location.map(
                            (paragraph, index) => (
                              <span key={index}>
                                {paragraph.children.map((child, childIndex) => (
                                  <span key={childIndex}>{child.text}</span>
                                ))}
                              </span>
                            )
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="px-4 my-5 text-center border-bottom">
                <div className="overflow-hidden" style={{ maxHeight: "30vh" }}>
                  <div className="px-2">
                    <img
                      src={`http://localhost:1337${data.data.attributes.coverImage.data.attributes.url}`}
                      className="img-fluid border rounded-2 shadow mb-4"
                      alt="cover"
                      width="100%"
                      height="500"
                      loading="lazy"
                    />
                  </div>
                </div>

                <div className="col-lg-12 mx-auto p-4 lh-sm text-break text-start fs-6">
                  {data.data.attributes.description &&
                    data.data.attributes.description.map((paragraph, index) => (
                      <p key={index}>
                        {paragraph.children.map((child, childIndex) => (
                          <span key={childIndex}>{child.text}</span>
                        ))}
                      </p>
                    ))}
                </div>

                <div className="py-5 px-2">
                  <div className="mb-3 w-25 h-50 mx-auto d-block overflow-hidden">
                    <div className="">
                      <img
                        className="img-fluid border rounded-5 shadow mb-4 img-fluid img-thumbnail h-50 w-50"
                        alt="author"
                        src={`http://localhost:1337${data.data.attributes.authorImage.data.attributes.url}`}
                      />
                    </div>
                    <div className="card-header">
                      {data.data.attributes.authorName}
                    </div>
                    <div className="card-body">
                      <p className="card-text">
                        {data.data.attributes.authorDescription}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EventCard;
