import React from 'react'
import Bank from '../images/icons/bank.png'

const Banking = () => {
    return (
        <div className="container px-4 py-5" id="hanging-icons">
            <div class="px-4 py-5 my-5 text-center">
                <img class="d-block mx-auto mb-4" src={Bank} alt="" width="100" />
                <h1 class="display-5 fw-bold">Banking in AUSTRALIA</h1>
                <div class="col-lg-12 mx-auto">
                    <p class="lead mb-4 textJustify">
                        There’s a lot to look forward to when you move to Australia to study. Not only can you enjoy world-class educational facilities, but every day can be an adventure. Whether you’re surfing at a sunny beach, tasting fresh seafood or discovering Australian wildlife there’s always something to look forward to. We want to make your move to the sunny side even smoother by introducing you to the Commonwealth Bank of Australia. They’re known to Aussies as CommBank.

                        CommBank is Australia’s largest bank. They’ve been helping Australian dreams come true since 1911, and support more people moving to Australia than any other bank. They’re also the bank that more Australian students choose, so they’ve got the experience to make sure your adventure is smooth sailing.

                        With a variety of products and services, you can get your banking sorted in no time. Find helpful hints and tips about moving to and studying in Australia by visiting their Moving to Australia website.

                        CommBank are excited to welcome you to the land down under.

                    </p>
                    <span className=''>
                        Things you should know: Commonwealth Bank of Australia ABN 48 123 123 124 is a company incorporated in Australia with limited liability and subject to Australian regulation.
                    </span>

                </div>
            </div>
        </div>
    )
}

export default Banking