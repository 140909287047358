import React from "react";
import WA from "../../images/WhatsAppButtonGreenLarge.svg";

const MilesAction = () => {
  return (
    <div className="container py-5 mx-auto">
      <div className="">
        <div className="h-100 p-2 bg-info-subtle border rounded-3">
          <h2>
            Schedule a visit to Interface X Miles Education Office or meet us
            online.
          </h2>
          <p className="fs-5 pt-4">
            Secure your spot now! that could redefine your professional future.
          </p>
          <button
            className="btn btn-outline-warning px-5 mb-3"
            type="button"
            onClick={() =>
              window.open(
                "https://docs.google.com/forms/d/1_g9PfMUtCyoSKVd6RPf27cSaoZ1uq17RAVpe6iUI400/",
                "_blank"
              )
            }
          >
            Register Now !
          </button>
          <p className="h3">or,</p>
          <p className="h2">Call us at +977-9841185456</p>
          <p className="h4 pt-4"> or, connect with us on</p>
          <a 
            aria-label="Chat on WhatsApp"
            type="button"
            onClick={() => window.open("https://wa.me/9779841185456", "_blank")}
          >
            <img className="" alt="Chat on WhatsApp" src={WA} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default MilesAction;
