import React from "react";
import { Link } from "react-router-dom";

const QnA = () => {
  return (
    <>
      <div className="container p-4">
        <h2 className="pb-2 border-bottom">Q & A</h2>
        <div className="row justify-content-center py-4">
          <div className="col-md-3 p-2">
            <div className="embed-responsive embed-responsive-4by3">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/2jLAAJeJuVQ"
                title="Youtube Videos"
                allow="fullscreen;"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div className="col-md-3 p-2">
            <div className="embed-responsive embed-responsive-4by3">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/kcgwXUel-Xw"
                title="Youtube Videos"
                allow="fullscreen;"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
        <div className="flex py-4">
          <button
            type="button"
            className="btn btn-primary float-end"
            data-bs-toggle="modal"
            data-bs-target="#QnA"
          >
            {" "}
            More Q&A
          </button>
        </div>
      </div>
      <div
        className="modal fade"
        tabIndex="-1"
        id="QnA"
        data-bs-keyboard="false"
        aria-labelledby="QnALabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered rounded rounded-4 text-dark">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="QnALabel">
                Questions & Answers
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <p>For more videos...</p>
              <p className="">
                Follow our channel "
                <span className="fw-bold text-warning">
                  Interface Education Center
                </span>
                " on youtube.
              </p>
              <a
                href='https://www.youtube.com/channel/UCYaQArAOGzgt7m1YSZbKHtA'
                target="_blank"
                className="text-muted"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="bi"
                  width="48"
                  height="48"
                  fill="currentColor"
                  id="youtube"
                  viewBox="0 0 16 16"
                >
                  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
                </svg>
              </a>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Got It!
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QnA;
