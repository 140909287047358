import React from "react";
import img1 from "../images/icons/medal.png";
import img2 from "../images/icons/review.png";
import img3 from "../images/icons/to.png";
import img4 from "../images/icons/web.png";
import img5 from "../images/icons/target.png";
import img6 from "../images/icons/gear.png";
import Ch from "../images/teams/chairman.jpg";
import ContactBanner from "./ContactBanner";

const aboutUs = () => {
  return (
    <div className="container p-4">
      <div className="col-md-12 p-lg-5 mx-auto">
        <h1 className="display-4 fw-normal">Why US?</h1>
        <p className="lead fw-normal textJustify">
          Interface education Center has 30 years of excellence in the industry.
          Interface education Center provides the best counselling, IELTS, PTE
          classes and options of best universities and colleges for student to
          study in Australia were as Interface is also known for high visa
          success rate. Interface education Center not only focuses on
          institutions or trust worthy information but it also helps with
          student SOP , scholarship assistance and post visa support.
        </p>
        <h4 className="fw-normal py-5 textJustify">
          OUR TEAM OFFERS BROAD RANGE OF STUDY ABROAD CONSULTING SERVICES FOR
          GLOBAL EDUCATION INSTITUTIONS
        </h4>
        <div className="row g-4 row-cols-1 row-cols-lg-3">
          <div className="feature col p-2">
            <div className="feature-icon text-center p-4">
              <img src={img1} height="50px" className="rounded" alt="" />

              <h5>
                Leader In International Student Recruitment Across Various
                Countries
              </h5>
            </div>
          </div>
          <div className="feature col p-2">
            <div className="feature-icon text-center p-4">
              <img src={img2} height="50px" className="rounded" alt="" />

              <h5>
                Extensive Experience In Promotion And Strategy Of International
                Education Institutions
              </h5>
            </div>
          </div>
          <div className="feature col p-2">
            <div className="feature-icon text-center p-4">
              <img src={img3} height="50px" className="rounded" alt="" />

              <h5>Solid Track Record</h5>
            </div>
          </div>
          <div className="feature col p-2">
            <div className="feature-icon text-center p-4">
              <img src={img4} height="50px" className="rounded" alt="" />
              <h5>Market Knowledge</h5>
            </div>
          </div>
          <div className="feature col p-2">
            <div className="feature-icon text-center p-4 ">
              <img src={img5} height="50px" className="rounded" alt="" />
              <h5>Prompt & Personalised Services</h5>
            </div>
          </div>
          <div className="feature col p-2">
            <div className="feature-icon text-center p-4">
              <img src={img6} height="50px" className="rounded" alt="" />
              <h5>Systematic Procedure</h5>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4">
        <div className="d-md-flex flex-md-equal w-100 border-bottom border-5">
          <div className="bg-light me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
            <div className="my-3 p-3">
              <h2 className="display-5">MISSION</h2>
            </div>
            <div className="p-4 styleAbout bg-warning shadow-sm lead textJustify">
              Our vision is to connect people with education globally, to
              further drive the international education sector through our
              platform in order to create an equality of outcome for future
              international students dreaming of a global experience.
            </div>
          </div>
          <div className="bg-light me-md-3 pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
            <div className="my-3 p-3">
              <h2 className="display-5">VISION</h2>
            </div>
            <div className="p-4 styleAbout bg-warning shadow-sm lead textJustify">
              To provide a simple platform that allows free and equal access to
              the world’s leading education institutions for our global
              recruitment partners and international students.
            </div>
          </div>
          <div className="bg-light pt-3 px-3 pt-md-5 px-md-5 text-center overflow-hidden">
            <div className="my-3 p-3">
              <h2 className="display-5">GOALS</h2>
            </div>
            <div className="p-4 styleAbout bg-warning shadow-sm lead textJustify">
              To provide a simple platform that allows free and equal access to
              the world’s leading education institutions for our global
              recruitment partners and international students.
            </div>
          </div>
        </div>
      </div>

      <div className="container p-4 my-4">
        <div className="d-flex flex-row p-4 pb-0 pe-lg-0 pt-lg-5 align-items-center rounded-3 border shadow-sm">
          <div className="col-lg-7 p-3 p-lg-5 pt-lg-3">
            <h1 className="display-5 fw-bold lh-1">Chairman's Voice</h1>
            <p className="lead py-2 textJustify">
              On behalf of the entire Interface fraternity, I would like to
              ensure that each and every student at our center is our priority.
              Being the pioneers of this industry with more than 30 years of
              experience, we strive to provide students with the best education
              opportunities from the universities and colleges from Australia
              and other countries. We promise you would find a wealth of
              learning opportunities that will rouse your interest and encourage
              you to take a step forward towards a fulling life and career.
              Interface education center is one of the most trusted institution
              by many education providers and we have more than 30,000 satisfied
              students who are placed all over the globe. 
            </p>
            <p className="lead py-2 textJustify">
              Studying abroad may be slightly daunting, but the reward in terms
              of education and personal development can be huge. There are top
              quality universities, cultural diversity, any major is welcome,
              great outdoors, easy access to student visa, internship
              availability, vibrant city life, wildlife, easy communication and
              incredible work opportunities makes Australia, the best study
              destination.
            </p>
            <p className="py-2 text-lg font-bold">Your dream is our
              dream!</p>
          </div>
          <div className="col-lg-4 offset-lg-1 overflow-hidden shadow">
            <img className="img-fluid" src={Ch} alt="" width="720" />
          </div>
        </div>
      </div>

      <div className="container p-4">
        <h1 className="display-4 lh-1 p-4">Our Team</h1>
        <div class="d-flex">
          <div class="col-lg-4 text-center p-4">
            <svg
              class="bd-placeholder-img rounded-circle"
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 140x140"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#777" />
              <text x="50%" y="50%" fill="#777" dy=".3em">
                140x140
              </text>
            </svg>

            <h2>Sandeepa Dahal</h2>
            <p></p>
            <p></p>
          </div>
          <div class="col-lg-4 text-center p-4">
            <svg
              class="bd-placeholder-img rounded-circle"
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 140x140"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#777" />
              <text x="50%" y="50%" fill="#777" dy=".3em">
                140x140
              </text>
            </svg>

            <h2>Prachi Poudyal</h2>
            <p>Officer, Admissions and Student Visa Services</p>
            <p></p>
          </div>
          <div class="col-lg-4 text-center p-4">
            <svg
              class="bd-placeholder-img rounded-circle"
              width="140"
              height="140"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-label="Placeholder: 140x140"
              preserveAspectRatio="xMidYMid slice"
              focusable="false"
            >
              <title>Placeholder</title>
              <rect width="100%" height="100%" fill="#777" />
              <text x="50%" y="50%" fill="#777" dy=".3em">
                140x140
              </text>
            </svg>

            <h2>Srijana Kharel</h2>
            <p></p>
            <p></p>
          </div>
        </div>
      </div>

      <ContactBanner />
    </div>
  );
};

export default aboutUs;
