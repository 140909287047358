const UniversitiesData =() => [

    {
        id:1,
        name:'The University of Sydney',
        img:'/images/universities/USydneyLogo.svg',
        address:'The University of Sydney, NSW 2006',
        country: 'Australia',
        description:'The University of Sydney, located in Sydney, Australia, is a world-renowned institution that is consistently ranked in the top 50 universities in the world (QS World University Rankings, 2020). It is also ranked first in Australia and fourth in the world for graduate employability by the QS Graduate Employability Rankings, 2020. Students come from all over the globe to study at undergraduate and postgraduate level.',
        program: ["Undergraduate", "Graduate"],
        courses: [],
        rank:'58',
        fees:'',
        intake:'',
        eURL:'https://www.sydney.edu.au/'
    },
    {
        id:2,
        name:'LINCOLN INSTITUTE OF HIGHER EDUCATION',
        img:'/images/universities/LIHE.jpg',
        address:'City Campus, Level 2, 191 Thomas Street, Sydney 2000 NSW',
        country: 'Australia',
        description:'Lincoln Institute of Higher Education trading as Lincoln Education Australia is a values-based, not-for-profit higher education institution offering innovative, contemporary courses and exceptional teaching in a high-quality, multi-cultural learning environment with modern facilities and wide-ranging academic and learning support services. LEA promotes a culture of performance excellence through regular professional learning activities and leadership programs.',
        program: ["Undergraduate", "Graduate"],
        courses: [],
        rank:'58',
        fees:'',
        intake:'',
        eURL:'https://www.lincolnau.nsw.edu.au/'
    },


]

export default UniversitiesData