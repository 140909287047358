import React, { useEffect, useState } from "react";

const useFetchArticles = (url) => {
  const [articleData, setArticleData] = useState(null);
  const [loadingA, setLoadingA] = useState(true);
  const [errorA, setErrorA] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url);
        const json = await response.json();
        setArticleData(json);
        setLoadingA(false);
      } catch (err) {
        setErrorA(err);
        setLoadingA(false);
      }
    };
    fetchData();
  }, []);
  return { loadingA, errorA, articleData };
};

export default useFetchArticles;
