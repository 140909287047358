import React from "react";
import { useNavigate } from "react-router-dom";
import useInterfaceContext from "./hooks/useInterfaceContext";
import InterestedData from "./InterestedData";

const ServerData = () => {
  const navigate = useNavigate()
  const {user, saveUsername} = useInterfaceContext()

  const logOut = () =>{
    saveUsername(null)
    navigate("/")
  }

  return (
    <div className="">
      <div className="container d-flex justify-content-around pt-5">
        <div className="">
            Welcome, <span className="fw-bold fs-4">{user}</span>
        </div>
        <div type="button" className="btn btn-outline-warning p-1 px-2" onClick={()=>{logOut()}}>
          Logout
        </div>
      </div>
      <div className="">
        <InterestedData />
      </div>
    </div>
  );
};

export default ServerData;
