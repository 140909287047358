import React from 'react'
import { useNavigate } from 'react-router-dom'

const ContactBanner = () => {
    const Navigate = useNavigate()

    return (
        <div className='container'>
            <div className="row align-items-md-stretch p-4">

                <button className='btn btn-outline-warning text-dark' onClick={() => { Navigate('../Contact', { replace: true }) }}>
                    <div className=" h-100 p-5">
                        <h2>Lets talk with one of our Counsellor.</h2>
                        <p>
                            Interested in studying overseas, We can help you ease your path ahead.
                            Get in touch with us through any medium and we’ll get back to you.
                        </p>

                    </div>
                </button>
            </div>
        </div>
    )
}

export default ContactBanner