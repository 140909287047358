import React from "react";
import { useNavigate } from "react-router-dom";


const Reviews = ({ reData }) => {
  const navigate = useNavigate()

  return (
    <div className="container px-4 py-5">
      <h2 className="pb-2 border-bottom">Reviews</h2>
      <div
        id="carouselExampleControls"
        className="carousel slide py-2"
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {reData.map((curE) => {
            const { id, name, img, country, reviews } = curE;

            if (id === 1) {
              return (
                <div className="carousel-item active" key={id}>
                  <div className="d-block">
                    <div className="row p-4 justify-content-center">
                      <div className="p-2 col-2 marginRGT">
                        <img
                          src={img}
                          alt={name}
                          height="200px"
                          className="d-none d-md-block round-more shadow"
                        />
                        <span className="">{name},</span>
                        <span className="fw-bold">{country}</span>
                      </div>
                      <div
                        className="p-2 col-6 border border-5 border-warning rounded"
                        height="500px"
                      >
                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-9">
                            <div className="textCENTER">{reviews}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="carousel-item" key={id}>
                  <div className="d-block">
                    <div className="row p-4 justify-content-center">
                      <div className="p-2 col-2 marginRGT">
                        <img
                          src={img}
                          alt={name}
                          height="200px"
                          className="d-none d-md-block round-more shadow"
                        />
                        <span className="">{name},</span>
                        <span className="fw-bold">{country}</span>
                      </div>
                      <div
                        className="p-2 col-6 border border-5 border-warning rounded"
                        height="500px"
                      >
                        <div className="row">
                          <div className="col-2"></div>
                          <div className="col-9">
                            <div className="textCENTER">{reviews}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}

          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon bg-dark"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleControls"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon bg-dark"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>

      <button className="btn btn-primary float-end" onClick={()=>navigate('/allReviews')}>All Reviews</button>
    </div>
  );
};

export default Reviews;
