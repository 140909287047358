import React from "react";
import AUS from "../images/icons/sydney.png";
import imgAus from "../images/hd/aus.jpg";
import ContactBanner from "../components/ContactBanner";

const Australia = () => {
  return (
    <div className="container p-4">
      <div className="p-4 my-5 text-center">
        <img className="d-block mx-auto mb-4" src={AUS} alt="" width="150" />
        <h1 className="display-5 fw-bold">Why AUSTRALIA?</h1>

        <p className="lead mb-4 textJustify">
          When people think of Australia, they see wide open spaces of outback
          bush, kangaroos, koalas, and clean air and water. Australia has much
          more to offer than the usual expectations. Many international students
          are choosing to study in Australia because of its friendly, laid-back
          nature, excellent education system, and high standard of living.
        </p>
        <p className="lead mb-4 textJustify">
          Australia promotes innovation, creativity, and independent thinking
          throughout its universities. International students who study and live
          in Australia soon find that their education is challenging, fun, and
          rewarding. As an international student in Australia, you can expect to
          live, grow, and learn in a young, friendly country full of
          opportunities. For those international students who successfully
          complete their degree, they will soon find that they are competitive
          in today’s international job market.
        </p>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 py-5">
          <div className="col d-flex align-items-start p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              className="bi text-muted flex-shrink-0 "
              width="1.75em"
              height="1.75em"
            >
              <path d="M408 120C408 174.6 334.9 271.9 302.8 311.1C295.1 321.6 280.9 321.6 273.2 311.1C241.1 271.9 168 174.6 168 120C168 53.73 221.7 0 288 0C354.3 0 408 53.73 408 120zM288 152C310.1 152 328 134.1 328 112C328 89.91 310.1 72 288 72C265.9 72 248 89.91 248 112C248 134.1 265.9 152 288 152zM425.6 179.8C426.1 178.6 426.6 177.4 427.1 176.1L543.1 129.7C558.9 123.4 576 135 576 152V422.8C576 432.6 570 441.4 560.9 445.1L416 503V200.4C419.5 193.5 422.7 186.7 425.6 179.8zM150.4 179.8C153.3 186.7 156.5 193.5 160 200.4V451.8L32.91 502.7C17.15 508.1 0 497.4 0 480.4V209.6C0 199.8 5.975 190.1 15.09 187.3L137.6 138.3C140 152.5 144.9 166.6 150.4 179.8H150.4zM327.8 331.1C341.7 314.6 363.5 286.3 384 255V504.3L192 449.4V255C212.5 286.3 234.3 314.6 248.2 331.1C268.7 357.6 307.3 357.6 327.8 331.1L327.8 331.1z" />
            </svg>
            <div>
              <h4 className="mb-2">Growing Destination</h4>
              <p className="textJustify">
                Australia is currently the third most popular destination for
                international students in the English-speaking world, behind the
                United States and the UK. Many international students choose to
                study there because of the cultural diversity, friendly natives,
                and high quality of education.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="bi text-muted flex-shrink-0"
              width="1.75em"
              height="1.75em"
            >
              <path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM51.68 295.1L83.41 301.5C91.27 303.1 99.41 300.6 105.1 294.9L120.5 279.5C132 267.1 151.6 271.1 158.9 285.8L168.2 304.3C172.1 313.9 182.8 319.1 193.5 319.1C208.7 319.1 219.6 305.4 215.2 290.8L209.3 270.9C204.6 255.5 216.2 240 232.3 240H234.6C247.1 240 260.5 233.3 267.9 222.2L278.6 206.1C284.2 197.7 283.9 186.6 277.8 178.4L261.7 156.9C251.4 143.2 258.4 123.4 275.1 119.2L292.1 114.1C299.6 113.1 305.7 107.8 308.6 100.6L324.9 59.69C303.4 52.12 280.2 48 255.1 48C141.1 48 47.1 141.1 47.1 256C47.1 269.4 49.26 282.5 51.68 295.1L51.68 295.1zM450.4 300.4L434.6 304.9C427.9 306.7 420.8 304 417.1 298.2L415.1 295.1C409.1 285.7 398.7 279.1 387.5 279.1C376.4 279.1 365.1 285.7 359.9 295.1L353.8 304.6C352.4 306.8 350.5 308.7 348.2 309.1L311.1 330.1C293.9 340.2 286.5 362.5 294.1 381.4L300.5 393.8C309.1 413 331.2 422.3 350.1 414.9L353.5 413.1C363.6 410.2 374.8 411.8 383.5 418.1L385 419.2C422.2 389.7 449.1 347.8 459.4 299.7C456.4 299.4 453.4 299.6 450.4 300.4H450.4zM156.1 367.5L188.1 375.5C196.7 377.7 205.4 372.5 207.5 363.9C209.7 355.3 204.5 346.6 195.9 344.5L163.9 336.5C155.3 334.3 146.6 339.5 144.5 348.1C142.3 356.7 147.5 365.4 156.1 367.5V367.5zM236.5 328.1C234.3 336.7 239.5 345.4 248.1 347.5C256.7 349.7 265.4 344.5 267.5 335.9L275.5 303.9C277.7 295.3 272.5 286.6 263.9 284.5C255.3 282.3 246.6 287.5 244.5 296.1L236.5 328.1zM321.7 120.8L305.7 152.8C301.7 160.7 304.9 170.4 312.8 174.3C320.7 178.3 330.4 175.1 334.3 167.2L350.3 135.2C354.3 127.3 351.1 117.6 343.2 113.7C335.3 109.7 325.6 112.9 321.7 120.8V120.8z" />
            </svg>
            <div>
              <h4 className="mb-2">Global Recognition</h4>
              <p className="textJustify">
                Graduates from Australian schools are highly sought after due to
                the impressive international reputation of the Australian
                education system. This system is carefully regulated by the
                government in order to maintain the high standards of education
                associated with the country.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              className="bi text-muted flex-shrink-0"
              width="1.75em"
              height="1.75em"
            >
              <path d="M592 224C565.5 224 544 245.5 544 272V352H96V272C96 245.5 74.51 224 48 224S0 245.5 0 272v192C0 472.8 7.164 480 16 480h64c8.836 0 15.1-7.164 15.1-16L96 448h448v16c0 8.836 7.164 16 16 16h64c8.836 0 16-7.164 16-16v-192C640 245.5 618.5 224 592 224zM128 272V320h384V272c0-38.63 27.53-70.95 64-78.38V160c0-70.69-57.31-128-128-128H191.1c-70.69 0-128 57.31-128 128L64 193.6C100.5 201.1 128 233.4 128 272z" />
            </svg>
            <div>
              <h4 className="mb-2">Cost of Living</h4>
              <p className="textJustify">
                Australia’s standard of living is amongst the highest in the
                world. Living expenses and tuition costs are considerably lower
                in Australia than they are in the United States and United
                Kingdom. International students are able to work part time while
                they study, allowing them to offset their living costs. There is
                also the possibility of scholarships, which helps to lower the
                cost of studying for international students.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              className="bi text-muted flex-shrink-0"
              width="1.75em"
              height="1.75em"
            >
              <path d="M623.1 136.9l-282.7-101.2c-13.73-4.91-28.7-4.91-42.43 0L16.05 136.9C6.438 140.4 0 149.6 0 160s6.438 19.65 16.05 23.09L76.07 204.6c-11.89 15.8-20.26 34.16-24.55 53.95C40.05 263.4 32 274.8 32 288c0 9.953 4.814 18.49 11.94 24.36l-24.83 149C17.48 471.1 25 480 34.89 480H93.11c9.887 0 17.41-8.879 15.78-18.63l-24.83-149C91.19 306.5 96 297.1 96 288c0-10.29-5.174-19.03-12.72-24.89c4.252-17.76 12.88-33.82 24.94-47.03l190.6 68.23c13.73 4.91 28.7 4.91 42.43 0l282.7-101.2C633.6 179.6 640 170.4 640 160S633.6 140.4 623.1 136.9zM351.1 314.4C341.7 318.1 330.9 320 320 320c-10.92 0-21.69-1.867-32-5.555L142.8 262.5L128 405.3C128 446.6 213.1 480 320 480c105.1 0 192-33.4 192-74.67l-14.78-142.9L351.1 314.4z" />
            </svg>

            <div>
              <h4 className="fw-bold mb-0">Diversity of Education</h4>
              <p className="textJustify">
                Institutions in Australia offer a wide variety of courses and
                degrees, so international students can easily find the school
                and field that are right for them. The first decision
                international students have to make when choosing a degree
                program is which school caters most to their needs and
                interests. Students can choose between universities, vocational
                education, and English language training. If necessary, it is
                easy for students to move between one qualification level and
                from one institution to another.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="bi text-muted flex-shrink-0"
              width="1.75em"
              height="1.75em"
            >
              <path d="M160 352h192V160H160V352zM448 176h48C504.8 176 512 168.8 512 160s-7.162-16-16-16H448V128c0-35.35-28.65-64-64-64h-16V16C368 7.164 360.8 0 352 0c-8.836 0-16 7.164-16 16V64h-64V16C272 7.164 264.8 0 256 0C247.2 0 240 7.164 240 16V64h-64V16C176 7.164 168.8 0 160 0C151.2 0 144 7.164 144 16V64H128C92.65 64 64 92.65 64 128v16H16C7.164 144 0 151.2 0 160s7.164 16 16 16H64v64H16C7.164 240 0 247.2 0 256s7.164 16 16 16H64v64H16C7.164 336 0 343.2 0 352s7.164 16 16 16H64V384c0 35.35 28.65 64 64 64h16v48C144 504.8 151.2 512 160 512c8.838 0 16-7.164 16-16V448h64v48c0 8.836 7.164 16 16 16c8.838 0 16-7.164 16-16V448h64v48c0 8.836 7.164 16 16 16c8.838 0 16-7.164 16-16V448H384c35.35 0 64-28.65 64-64v-16h48c8.838 0 16-7.164 16-16s-7.162-16-16-16H448v-64h48C504.8 272 512 264.8 512 256s-7.162-16-16-16H448V176zM384 368c0 8.836-7.162 16-16 16h-224C135.2 384 128 376.8 128 368v-224C128 135.2 135.2 128 144 128h224C376.8 128 384 135.2 384 144V368z" />
            </svg>
            <div>
              <h4 className="fw-bold mb-0">Technology</h4>
              <p className="textJustify">
                One of the most appealing aspects of Australia for international
                students is the emphasis on scientific research. Australia is at
                the forefront of new technology and innovations. Students who
                study in Australia can take advantage of the country’s
                impressive technology and resources.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start p-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="bi text-muted flex-shrink-0"
              width="1.75em"
              height="1.75em"
            >
              <path d="M320 336c0 8.844-7.156 16-16 16h-96C199.2 352 192 344.8 192 336V288H0v144C0 457.6 22.41 480 48 480h416c25.59 0 48-22.41 48-48V288h-192V336zM464 96H384V48C384 22.41 361.6 0 336 0h-160C150.4 0 128 22.41 128 48V96H48C22.41 96 0 118.4 0 144V256h512V144C512 118.4 489.6 96 464 96zM336 96h-160V48h160V96z" />
            </svg>

            <div>
              <h4 className="fw-bold mb-0">Work</h4>
              <p className="textJustify">
                International students are allowed to work up to 20 hours per
                week while studying in Australia. This is a great opportunity
                for those who want to earn money to offset living expenses
                during their stay, and for students who want to gain work
                experience in their field of interest while they study.
              </p>
            </div>
          </div>
          <div className="col d-flex align-items-start"></div>
          <div className="col d-flex align-items-start"></div>
        </div>
      </div>
      <div className="my-5">
        <img
          src={imgAus}
          className="img-fluid border rounded shadow"
          alt="Australia"
        />
      </div>

      <div className="row g-4 py-5 row-cols-1 row-cols-lg-3 border-top">
        <div className="feature col border-end textJustify p-4">
          <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              className="text-warning"
              width={50}
            >
              <path
                fill="currentColor"
                d="M241.8 111.9C250.7 121.8 249.9 136.1 240.1 145.8L160.1 217.8C150.9 226.1 137.1 226.1 127.9 217.8L47.94 145.8C38.09 136.1 37.29 121.8 46.16 111.9C55.03 102.1 70.2 101.3 80.05 110.2L119.1 146.1V24C119.1 10.75 130.7 0 143.1 0C157.3 0 167.1 10.75 167.1 24V146.1L207.9 110.2C217.8 101.3 232.1 102.1 241.8 111.9H241.8zM364.6 134.5C376.1 125.8 391.9 125.8 403.4 134.5L571.4 262.5C578 267.6 582.4 275 583.6 283.3L608.4 448C625.9 448.2 640 462.4 640 480C640 497.7 625.7 512 608 512H32C14.33 512 0 497.7 0 480C0 462.3 14.33 448 32 448H159.6L184.4 283.3C185.6 275 189.1 267.6 196.6 262.5L364.6 134.5zM384 448H460.8L384 320V448z"
              />
            </svg>
          </div>
          <h2 className="text-center">Living in Australia</h2>
          <p>
            Living in Australia will be different from life in your home
            country. To prepare for an experience in a foreign country far from
            home, we could provide help, advice, and information about what life
            is like in Australia.
          </p>
          <button
            className="d-inline-flex align-items-center btn btn-primary "
            data-bs-toggle="modal"
            data-bs-target="#staticLivinginAustralia"
          >
            <span>More</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="M246.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L178.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C47.63 444.9 55.81 448 64 448s16.38-3.125 22.62-9.375l160-160C259.1 266.1 259.1 245.9 246.6 233.4zM438.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L370.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C239.6 444.9 247.8 448 256 448s16.38-3.125 22.62-9.375l160-160C451.1 266.1 451.1 245.9 438.6 233.4z"
              />
            </svg>
          </button>
        </div>
        <div className="feature col border-end textJustify p-4">
          <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              className="text-warning"
              width={50}
            >
              <path
                fill="currentColor"
                d="M272 95.93c26.5 0 47.99-21.47 47.99-47.97S298.5 0 272 0C245.5 0 224 21.47 224 47.97S245.5 95.93 272 95.93zM209.7 357.3c-25.75-17.25-52.25-33.24-79.5-48.11L58.62 270.2L1.246 471.1c-4.875 16.1 4.1 34.74 22 39.62s34.63-4.998 39.5-21.99l36.63-128.1l60.63 40.37v78.86c0 17.62 14.38 31.99 32 31.99s32-14.37 32-31.99l.0022-95.93C224 373.2 218.6 363.2 209.7 357.3zM311.1 416c-13.88 0-25.95 8.863-30.33 21.86l-24.75 74.07h319.9l-101.9-206.3c-11.38-22.49-43.1-23.63-56.1-2.01l-31.89 54.21l-65.26-35.64l-24-121.2C288.1 161.3 263.2 127.7 227.1 109.7c-1-.4999-2.125-.625-3.125-1.125c-2.25-1.125-4.752-1.1-7.252-2.625C201.5 99.85 185.2 95.98 168.7 95.98H95.1c-9.25 0-18.05 4.061-24.18 10.93l-55.95 63.92c-.75 .9998-1.5 2.124-2.25 3.249c-8.875 13.1-3 32.87 11.63 40.74l336.6 184.3l-9.837 16.87H311.1zM105.9 204.1l-23.5-12.87l28.13-32.12h34.38L105.9 204.1zM199.5 256.1l34.9-41.28l13.5 67.61L199.5 256.1z"
              />
            </svg>
          </div>
          <h2 className="text-center">Working in Australia</h2>
          <p>
            International students must find a job on their own, but once hired,
            they will gain hands-on experience in a number of fields including
            retail, administration, and hospitality. Not only is this a unique
            opportunity for students to gain real work-experience in Australia,
            it also helps students cover their living and college expenses as
            well as improves their business English fluency.
          </p>
          <button
            className="d-inline-flex align-items-center btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#staticWorkinginAustralia"
          >
            <span>More</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="M246.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L178.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C47.63 444.9 55.81 448 64 448s16.38-3.125 22.62-9.375l160-160C259.1 266.1 259.1 245.9 246.6 233.4zM438.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L370.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C239.6 444.9 247.8 448 256 448s16.38-3.125 22.62-9.375l160-160C451.1 266.1 451.1 245.9 438.6 233.4z"
              />
            </svg>
          </button>
        </div>
        <div className="feature col textJustify p-4">
          <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="text-warning"
              width={50}
            >
              <path
                fill="currentColor"
                d="M480 144V384l-96 96H144C117.5 480 96 458.5 96 432v-288C96 117.5 117.5 96 144 96h288C458.5 96 480 117.5 480 144zM384 264C384 259.6 380.4 256 376 256H320V200C320 195.6 316.4 192 312 192h-48C259.6 192 256 195.6 256 200V256H200C195.6 256 192 259.6 192 264v48C192 316.4 195.6 320 200 320H256v56c0 4.375 3.625 8 8 8h48c4.375 0 8-3.625 8-8V320h56C380.4 320 384 316.4 384 312V264zM0 360v-240C0 53.83 53.83 0 120 0h240C373.3 0 384 10.75 384 24S373.3 48 360 48h-240C80.3 48 48 80.3 48 120v240C48 373.3 37.25 384 24 384S0 373.3 0 360z"
              />
            </svg>
          </div>
          <h2 className="text-center">Insurance in Australia</h2>
          <p>
            Many international students traveling and studying in Australia will
            want to have a private health insurance plan in place for the full
            duration of their time abroad. It’s important to think about what
            type of coverage you’ll need and if you plan to do any international
            traveling. Students often opt to apply for travel medical insurance
            coverage for Australia so that they will be covered should the
            unexpected happen. Whether you get sick, need emergency care, or
            need evacuation coverage, international travel medical insurance
            will be helpful.
          </p>
          <button
            className="d-inline-flex align-items-center btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#staticInsuranceinAustralia"
          >
            <span>More</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="M246.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L178.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C47.63 444.9 55.81 448 64 448s16.38-3.125 22.62-9.375l160-160C259.1 266.1 259.1 245.9 246.6 233.4zM438.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L370.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C239.6 444.9 247.8 448 256 448s16.38-3.125 22.62-9.375l160-160C451.1 266.1 451.1 245.9 438.6 233.4z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="row g-4 py-5 row-cols-1 row-cols-lg-3 border-top">
        <div className="feature col border-end textJustify p-4">
          <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              className="text-warning"
              width={50}
            >
              <path
                fill="currentColor"
                d="M512 64C547.3 64 576 92.65 576 128V384C576 419.3 547.3 448 512 448H64C28.65 448 0 419.3 0 384V128C0 92.65 28.65 64 64 64H512zM272 192C263.2 192 256 199.2 256 208C256 216.8 263.2 224 272 224H496C504.8 224 512 216.8 512 208C512 199.2 504.8 192 496 192H272zM272 320H496C504.8 320 512 312.8 512 304C512 295.2 504.8 288 496 288H272C263.2 288 256 295.2 256 304C256 312.8 263.2 320 272 320zM164.1 160C164.1 148.9 155.1 139.9 143.1 139.9C132.9 139.9 123.9 148.9 123.9 160V166C118.3 167.2 112.1 168.9 108 171.1C93.06 177.9 80.07 190.5 76.91 208.8C75.14 219 76.08 228.9 80.32 237.8C84.47 246.6 91 252.8 97.63 257.3C109.2 265.2 124.5 269.8 136.2 273.3L138.4 273.9C152.4 278.2 161.8 281.3 167.7 285.6C170.2 287.4 171.1 288.8 171.4 289.7C171.8 290.5 172.4 292.3 171.7 296.3C171.1 299.8 169.2 302.8 163.7 305.1C157.6 307.7 147.7 309 134.9 307C128.9 306 118.2 302.4 108.7 299.2C106.5 298.4 104.3 297.7 102.3 297C91.84 293.5 80.51 299.2 77.02 309.7C73.53 320.2 79.2 331.5 89.68 334.1C90.89 335.4 92.39 335.9 94.11 336.5C101.1 339.2 114.4 343.4 123.9 345.6V352C123.9 363.1 132.9 372.1 143.1 372.1C155.1 372.1 164.1 363.1 164.1 352V346.5C169.4 345.5 174.6 343.1 179.4 341.9C195.2 335.2 207.8 322.2 211.1 303.2C212.9 292.8 212.1 282.8 208.1 273.7C204.2 264.7 197.9 258.1 191.2 253.3C179.1 244.4 162.9 239.6 150.8 235.9L149.1 235.7C135.8 231.4 126.2 228.4 120.1 224.2C117.5 222.4 116.7 221.2 116.5 220.7C116.3 220.3 115.7 219.1 116.3 215.7C116.7 213.7 118.2 210.4 124.5 207.6C130.1 204.7 140.9 203.1 153.1 204.1C157.5 205.7 171 208.3 174.9 209.3C185.5 212.2 196.5 205.8 199.3 195.1C202.2 184.5 195.8 173.5 185.1 170.7C180.7 169.5 170.7 167.5 164.1 166.3L164.1 160z"
              />
            </svg>
          </div>
          <h2 className="text-center">Financial aid in Australia</h2>
          <p>
            Studying in Australia can be expensive. The key to success is to
            budget ahead of time and secure financial assistance. Once you have
            verified your costs, the next step is calculating how much you and
            your family can afford. International organizations, international
            scholarships, international student loans, and private Australian
            organizations can be important funding sources that can help you
            study in Australia. Read our Financial Aid section to learn more.
          </p>
          <button
            className="d-inline-flex align-items-center btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#staticFinanceinAustralia"
          >
            <span>More</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="M246.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L178.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C47.63 444.9 55.81 448 64 448s16.38-3.125 22.62-9.375l160-160C259.1 266.1 259.1 245.9 246.6 233.4zM438.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L370.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C239.6 444.9 247.8 448 256 448s16.38-3.125 22.62-9.375l160-160C451.1 266.1 451.1 245.9 438.6 233.4z"
              />
            </svg>
          </button>
        </div>
        <div className="feature col border-end textJustify p-4">
          <div className="feature-icon d-inline-flex align-items-center justify-content-center fs-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="text-warning"
              height={50}
            >
              <path
                fill="currentColor"
                d="M32 176h370.8l-57.38 57.38c-12.5 12.5-12.5 32.75 0 45.25C351.6 284.9 359.8 288 368 288s16.38-3.125 22.62-9.375l112-112c12.5-12.5 12.5-32.75 0-45.25l-112-112c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L402.8 112H32c-17.69 0-32 14.31-32 32S14.31 176 32 176zM480 336H109.3l57.38-57.38c12.5-12.5 12.5-32.75 0-45.25s-32.75-12.5-45.25 0l-112 112c-12.5 12.5-12.5 32.75 0 45.25l112 112C127.6 508.9 135.8 512 144 512s16.38-3.125 22.62-9.375c12.5-12.5 12.5-32.75 0-45.25L109.3 400H480c17.69 0 32-14.31 32-32S497.7 336 480 336z"
              />
            </svg>
          </div>
          <h2 className="text-center">Tax Returns in Australia</h2>
          <p>
            While studying in Australia as an international student you will
            likely have the ability to work. Since taxes will be taken out of
            your pay, you will need to also lodge a tax return each year. Filing
            a tax return can be a complicated process, but our Australian tax
            return information can help introduce you to this topic and keep you
            on track. Explore answers to some of the frequently asked questions
            around Australian tax returns, a step by step guide to getting
            started, and where you can turn if you need assistance filing.
          </p>
          <button className="d-inline-flex align-items-center btn btn-primary"  data-bs-toggle="modal"
            data-bs-target="#staticTaxinAustralia">
            <span>More</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              width="1em"
              height="1em"
            >
              <path
                fill="currentColor"
                d="M246.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L178.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C47.63 444.9 55.81 448 64 448s16.38-3.125 22.62-9.375l160-160C259.1 266.1 259.1 245.9 246.6 233.4zM438.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L370.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C239.6 444.9 247.8 448 256 448s16.38-3.125 22.62-9.375l160-160C451.1 266.1 451.1 245.9 438.6 233.4z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="border-top text-center py-4">
        <h3 className="display-6 ">
          If you are interested in Australia, we can help provide information on
          selecting right program/university, visa requirements, working
          permissions, landing a job, and more.
        </h3>
      </div>
      <ContactBanner />
      <div
        className="modal fade"
        id="staticLivinginAustralia"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl rounded rounded-4 text-dark modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content p-2">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Living in Australia
              </h5>
            </div>
            <div className="modal-body p-4">
              <h5
                className="fs-6 fw-bold text-center py-2"
                id="staticBackdropLabel"
              >
                Australia is a land of diversity and culture. Living in a new
                country can be very different and your lifestyle is likely to
                change.
              </h5>
              <div className="list-group">
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">Map & Climate</h5>
                    <p className="mb-1 col dropcap textJustify">
                      For international students, the climate in Australia may
                      take some getting used to, as the Australian seasons are
                      opposite to those in Europe and North America. The climate
                      in Australia varies widely depending on the area, as
                      Australia consists of a variety of environments: from
                      beaches to deserts to grazing lands to coral reefs.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">People & Culture</h5>
                    <p className="mb-1 col dropcap textJustify">
                      Australia is an extremely culturally diverse country. It
                      is varied in its religion, language, history, and art.
                      International students who study in Australia will find
                      themselves studying in a fascinating and diverse
                      environment.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">Accomodation</h5>
                    <p className="mb-1 col dropcap textJustify">
                      One of the first things international students will have
                      to determine when preparing to study in Australia is where
                      to live. The majority of Australian students live
                      off-campus through homestay programs, in hostels, or
                      rental properties. However, some Australian universities
                      provide housing options, which allows international
                      students to socialize with local students.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">Getting Around</h5>
                    <p className="mb-1 col dropcap textJustify">
                      Australia is an extremely large country, making
                      transportation very important. The public transportation
                      system is very efficient, and international students
                      should always be able to get to where they need to go,
                      whether by bus, train, rideshare, bike, or plane.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">Financial Status</h5>
                    <p className="mb-1 col dropcap textJustify">
                      Tuition in Australia is generally less expensive than
                      tuition in other countries like the United States or
                      United Kingdom, but it is still important for
                      international students to budget and be careful with their
                      finances.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">Visa Requirement</h5>
                    <p className="mb-1 col dropcap textJustify">
                      The Australian Government allows people who are not
                      Australian citizens or permanent residents to study in
                      Australia. There are several requirements that must be
                      fulfilled. Contact us to find out which forms you need and
                      where to obtain them.
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticWorkinginAustralia"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl rounded rounded-4 text-dark modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content p-2">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Working in Australia
              </h5>
            </div>
            <div className="modal-body p-4">
              <h5
                className="fs-6 fw-bold text-center py-2"
                id="staticBackdropLabel"
              >
                As an international student, you should never expect to live on
                the money you make while studying, but the extra income can
                certainly help you meet your financial needs.
              </h5>
              <div className="list-group">
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">Work Rules</h5>
                    <p className="mb-1 col dropcap textJustify">
                      International students in Australia on a valid student
                      visa can work for up to 20 hours per week while school is
                      in session, and there is no limit on the number of hours
                      an international student can work during recognized school
                      vacations. A work week in Australia is considered to be
                      Monday through the following Sunday. Although they are
                      allowed to arrive in Australia up to 90 days before their
                      course begins, students are not allowed to begin working
                      until after their courses have begun. The 20-hour-per-week
                      limit does not extend to any work the student is required
                      to undertake as a component of his or her studies or
                      training. Volunteer and unpaid work, however, does count
                      towards the 20 hours. If an international student works
                      more than the Australian restrictions allow, his or her
                      visa may be cancelled.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">Finding a Job</h5>
                    <p className="mb-1 col dropcap textJustify">
                      One of the first things you should do when searching for a
                      job is to research the companies you are interested in.
                      After you have done your research, you should send in an
                      application or resume to the company and schedule an
                      interview. In order to acquire a job a student must exude
                      good manners and punctuality when interviewing. Have your
                      phone on you at all times in case a prospective employer
                      calls. Be persistent. Remember when looking for a job that
                      school comes first so the jobs you'll look for will have
                      hours during the night. Visit Work in Australia for more
                      tips about resume writing and the application process.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">Tax File Number</h5>
                    <p className="mb-1 col dropcap textJustify">
                      In order to work or open a bank account in Australia you
                      must obtain a TFN (Tax File Number). Your TFN is
                      confidential, like a social security number in the US, so
                      keep your number secure. You are only issued one in your
                      lifetime and it is used to file tax reports and important
                      government documents. If you work in Australia, you are
                      required to file a tax return, which is tracked to your
                      TFN. As an international student, you will likely receive
                      a refund when you file your tax return. For more
                      information on your potential tax refund visit
                      Taxback.com; they can help you get the tax refund you
                      deserve at an affordable rate. Part-time workers who have
                      used Taxback.com’s services have received an average of
                      AU$2600 back in tax refunds. Their team of professionals
                      is dedicated to serving international students.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">After Graduation</h5>
                    <p className="mb-1 col dropcap textJustify">
                      Unlike in some other English-speaking countries, there is
                      no automatic right associated with your student visa to
                      stay and work for a period of time in Australia after you
                      complete your degree. When you receive your student visa,
                      the government lets you in on a temporary basis of entry
                      for study with the understanding that you will leave upon
                      completion of course and before the visa will expire.
                      Australia continues to have demand for foreign workers,
                      and students that have earned a degree in Australia have a
                      special work visa category. There are also several other
                      visa categories open to international students, and
                      Australia has a very straightforward path to permanent
                      residency using a points system . If you want to stay and
                      work after you graduate, you must apply and obtain a work
                      visa.
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticInsuranceinAustralia"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl rounded rounded-4 text-dark modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content p-2">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Insurance in Australia
              </h5>
            </div>
            <div className="modal-body p-4">
              <h5
                className="fs-6 fw-bold text-center py-2"
                id="staticBackdropLabel"
              >
                Australia has a special health insurance coverage for
                international students called Overseas Student Health Cover
                (OSHC).
              </h5>
              <div className="list-group">
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <p className="mb-1 col dropcap textJustify">
                      OSHC is insurance designed to help international students
                      cover the costs of medical and hospital care that they may
                      need while in Australia. Because of these requirements, it
                      is important that international students purchase their
                      Australian international student health insurance prior to
                      departing their home country.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">Who must purchase OSHC?</h5>
                    <p className="mb-1 col dropcap textJustify">
                      The Department of Immigration and Citizenship requires
                      that all individuals carrying a student visa and their
                      dependents must maintain OSHC for the full duration of
                      their time in Australia except few exceptions.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">Who Purchases OSHC?</h5>
                    <p className="mb-1 col dropcap textJustify">
                      OSHC is purchased either by your education provider or you
                      can choose your own OSHC approved provider and pay the
                      insurance yourself. It’s important that regardless of who
                      purchases it, that you maintain continuous coverage for
                      the entire stay in Australia. This especially applies for
                      students who are studying through two different
                      institutions who organize the insurance on the
                      international student’s behalf.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">Which Insurers Offer OSHC?</h5>
                    <p className="mb-1 col dropcap textJustify">
                      Currently, there are 5 insurers that offer OSHC which can
                      be purchased through your academic institution or directly
                      on your own:
                      <ul>
                        <li>Australian Health Management</li>
                        <li>BUPA Australia</li>
                        <li>Medibank Private</li>
                        <li>
                          Allianz Global Assistance (subcontracted by Lysaght
                          Peoplecare)
                        </li>
                        <li>nib OSHC</li>
                      </ul>
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">What does OSHC cover?</h5>
                    <p className="mb-1 col dropcap textJustify">
                      The OSHC plan is designed to provide coverage for
                      international students seeking treatment at a doctor’s
                      office, hospital, or for limited prescription drugs.
                      Coverage will vary based on the plan you purchase so it’s
                      important to contact each OSHC provider to ensure that you
                      get the coverage you need. You will want to ask about
                      waiting periods, exclusions, and other limitations on the
                      plan. The OSHC insurance plan will not cover any treatment
                      arranged in advance of arrival to Australia, treatment to
                      and from Australia, as well as transportation into or out
                      of Australia. OSHC does not typically cover dental,
                      optical or physiotherapy either.
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticFinanceinAustralia"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl rounded rounded-4 text-dark modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content p-2">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Financial Aid in Australia
              </h5>
            </div>
            <div className="modal-body p-4">
              <h5
                className="fs-6 fw-bold text-center py-2"
                id="staticBackdropLabel"
              >
                While studying in Australia, you will need sufficient funds to
                cover living expenses, health insurance, and tuition.
              </h5>
              <div className="list-group">
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <p className="mb-1 col dropcap textJustify">
                      How much money will you need? You can get a general idea
                      about expenses by looking at catalogs or application
                      information provided by the university. Remember that
                      tuition rates can vary tremendously. Some universities
                      could be cheaper than others, so to get the best idea of
                      expenses you need to contact the schools you are
                      interested in. The cost of living in different parts of
                      Australia also varies. In general, living in urban areas
                      is more expensive than living in smaller towns or rural
                      areas. Renting an apartment in a big city can cost twice
                      as much as it does in a smaller town because there is such
                      high demand for housing in large Australian cities. Food,
                      clothing, entertainment, and other living expenses may be
                      costlier. Budgeting is a continuous process. At this
                      stage, work on a "big picture" budget that will include
                      tuition, room and board, transportation, and living
                      expenses. Later you can be more specific, taking into
                      consideration all the additional expenses of moving and
                      settling in. Remember, you should never count on earning
                      enough from working in Australia to pay your way.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <p className="mb-1 col dropcap fw-bold text-center">
                      For more information about financial aid sources, please
                      contact us regarding Scholarships/Loans
                    </p>
                  </div>
                </a>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="staticTaxinAustralia"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl rounded rounded-4 text-dark modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content p-2">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Taxes in Australia
              </h5>
            </div>
            <div className="modal-body p-4">
              <h5
                className="fs-6 fw-bold text-center py-2"
                id="staticBackdropLabel"
              >
                Tax season is quite a dreaded subject, especially when you are
                moving to a new country and are dealing with their tax laws for
                the first time.
              </h5>
              <div className="list-group">
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">
                      Do I have to pay tax on the income I earn in Australia?
                    </h5>
                    <p className="mb-1 col dropcap textJustify">
                      With the ability to work in Australia as an international
                      student comes the obligation of paying taxes. You will
                      have to pay tax on the income you earn while in Australia,
                      but the good news is that the majority of international
                      students in Australia are entitled to claim a tax refund
                      at the end of the year. Students that work part-time in
                      Australia pay on average 15.5% income tax on their
                      earnings. Of course, this will depend on certain
                      circumstances that are relevant to you, such as whether or
                      not you are deemed a resident or non-resident for tax
                      purposes.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">
                      Do I need to lodge a tax return as an international
                      student in Australia?
                    </h5>
                    <p className="mb-1 col dropcap textJustify">
                      Yes, anyone that works in Australia needs to lodge a
                      return at the end of the financial year regardless of
                      income earned. This obligation applies especially to
                      international students who earned income in Australia.
                      Many international students can be daunted by the prospect
                      of filing a tax return but the good news is that by filing
                      your tax return you can claim your tax refund!
                      Additionally, by filing a return you can also ensure that
                      your tax affairs are in order and this will be important
                      when applying for a future Australia visa. International
                      students in Australia who do not lodge a tax return risk
                      incurring fines and penalties from the Australian Taxation
                      Office (ATO) and will also miss out on a tax refund –
                      which can be a lot of money. The average Australian tax
                      refund of a Taxback.com customer claims is $2,600.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">
                      Am I an Australian resident or non-resident for tax
                      purposes?
                    </h5>
                    <p className="mb-1 col dropcap textJustify">
                      Knowing if you’re an Australian resident or non-resident
                      is important as it will affect how much tax you pay. How
                      do you know if you are a resident or nonresident? When it
                      comes to being a resident for tax purposes the main
                      requirement is that you have continuously resided in
                      Australia for a period of 183 days (6 months). If you have
                      enrolled in a course that lasts for less than 6 months,
                      and you intend to leave right after you finish, you will
                      be considered non-resident for tax purposes. What is the
                      tax difference for residents and non-residents?
                      Non-residents will be taxed at a higher rate than
                      residents.
                      <li>Non-residents: Pay 15% on their first $37,000</li>
                      <li>
                        Residents: Pay 0% on their first $18,200, then 19% from
                        $18,200-$37,000.
                      </li>
                      If you are studying in Australia for a duration of six
                      months or more- you are typically regarded as an
                      Australian resident for tax purposes. So although you may
                      be entitled to benefits of the Australian tax system like
                      the tax-free threshold and lower tax rates, you will still
                      need to pay tax on your earnings just like other
                      residents.
                    </p>
                  </div>
                </a>
                <a
                  href=""
                  className="list-group-item list-group-item-action p-4"
                >
                  <div className="d-flex w-100 gap-5">
                    <h5 className="mb-1 col-2">
                      When do I apply for a tax refund in Australia?
                    </h5>
                    <p className="mb-1 col dropcap textJustify">
                      You have to be in Australia for at least six months before
                      you can claim an Australian tax refund. The Australian
                      financial year runs from July 1 until June 30 every year
                      and the deadline to lodge your tax return and claim your
                      tax back is October 31. If you miss the deadline, you can
                      get extra time to lodge your return if you lodge with a
                      tax agent.
                    </p>
                  </div>
                </a>
                
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                data-bs-dismiss="modal"
              >
                Got it!
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Australia;
