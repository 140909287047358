import { useEffect, useState } from "react";

const useFetchSingleEvent = (url) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log(url, "here....");
  
  const fetchData = async () => {
    setLoading (true)
    try {
      const response = await fetch(url);
      const json = await response.json();
      setData(json);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(url, "here....");
    fetchData();
  }, []);

  return { loading, error, data };
};

export default useFetchSingleEvent;
