import React from "react";
import { Link } from "react-router-dom";
import useFetchEvents from "./hooks/useFetchEvents";
import Loading from "./pages/loading";
import Error from "./pages/error";
import useFetchArticles from "./hooks/useFetchArticles";

const Events = () => {
  let { loading, error, data } = useFetchEvents(
    "http://localhost:1337/api/events?populate=*"
  );
  //console.log(data);

  const { loadingA, errorA, articleData } = useFetchArticles(
    "http://localhost:1337/api/articles?populate=*"
  );

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(new Date(dateString));
  };

  return (
    <div className="container p-4">
      <div className="col-md-12 p-lg-5 mx-auto">
        <h2 className="pb-2 display-4 fw-normal border-bottom">
          Events & Articles
        </h2>
        <div className="p-4">
          <div className="col d-md-flex gap-5 justify-content-center">
            <div className="row col-lg-6 flex-fill">
              <span className="p-2 fs-4 fw-bold">Events</span>
              {loading && <Loading />}
              {error && <Error />}
              {data && (
                <>
                  <div className="list-group py-4">
                    {data.data.map((eve) => {
                      return (
                        <Link
                          key={eve.id}
                          to={`/event/${eve.id}`}
                          className="list-group-item list-group-item-action d-flex gap-3 py-3"
                          aria-current="true"
                        >
                          <div className="d-flex gap-2 w-100 justify-content-between">
                            <div>
                              <h6 className="mb-0">{eve.attributes.title}</h6>
                              <p className="mb-0 opacity-75">
                                {eve.attributes.tagline}
                              </p>
                            </div>
                            <small className="opacity-50 text-nowrap">
                              {formatDate(eve.attributes.date)}
                            </small>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
            <div className="row col-lg-6 flex-fill">
              <span className="p-2 fs-4 fw-bold">Articles</span>
              {loadingA && <Loading />}
              {errorA && <Error err={errorA} />}
              {articleData && (
                <div className="list-group align-items-start py-4">
                  {articleData.data.map((e) => {
                    return (
                      <Link
                      key={e.id}
                        to={`/article/${e.id}`}
                        className="list-group-item list-group-item-action d-flex gap-3 py-3"
                        aria-current="true"
                      >
                        <div className="d-flex gap-2 w-100 justify-content-between">
                          <div>
                            <h6 className="mb-0">{e.attributes.title}</h6>
                            <p className="mb-0 opacity-75">
                              {e.attributes.tagline}
                            </p>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
