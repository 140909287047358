import React from "react";
import pte from "../images/icons/eng.png";
import ContactBanner from "./ContactBanner";

const PTE = () => {
  return (
    <div className="container px-4 py-5">
      <div class="px-4 py-5 my-5 text-center">
        <img class="d-block mx-auto mb-4" src={pte} alt="" width="100" />
        <h1 class="display-5 fw-bold">PTE</h1>
        <p className="lead mb-4 textJustify">
          The Pearson Test of English (PTE Academic) is an English proficiency
          test that assesses your speaking, writing, listening and reading
          abilities. Many individuals must take it in order to study abroad in
          an English speaking country like Australia, or when they wish to
          become a permanent resident of such a country. We, {" "}
          <span className="fw-bold text-warning">
            Interface Education Center
          </span>{" "}, do provide classes for PTE with one of the qualified
          teacher. One thing that many people appreciate about the PTE academic
          is that is completely computer based, and therefore you can complete
          the entire thing in one sitting over the course of about 3 hours. All
          responses including speaking are assessed by computer, and for a lot
          of people this takes away the stress of having to speak with a human
          examiner.
        </p>
        <p className="lead mb-4 fw-bold textJustify">
          If you need to take the TOEFL for study or visa purposes, you may
          be wondering what to expect.{" "}
          <span className="fw-bold text-warning">
            Interface Education Center
          </span>{" "}
          will answer all of your questions that you may have and
          help you prepare for the test.
        </p>
      </div>
      <ContactBanner />
    </div>
  );
};

export default PTE;
